<div class="container">
    <div class="row">
        <div class="col-md-12">
                <div class="row pb-2">
                    <div class="col-md-11 pl-0 pr-0">
                        <mat-form-field class="example-full-width d-flex mailInput" appearance="outline">
                            <input matInput name="subject" [(ngModel)]="mailSubject" placeholder="Subject"/>
                        </mat-form-field>
                    </div>
                    <div class="col-md-1 pl-0">
                        <div class="squareBox">
                            <mat-icon svgIcon="attachment" class="attachment-icon"></mat-icon>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <angular-editor [(ngModel)]="htmlContent" [config]="config"><p style="font-family: 'sf_ui_displaylight'; color: #707070">Thanks & Regards,</p>
                            <p><img src="./../../assets/svg/proteenMailSign.svg" style="width:150px; height: auto; display: block;"></p></angular-editor>
                    </div>
                </div>
                              
            <div class="row">
                <div class="col-md-12 pt-3">
                    <div class="justify-content-end">
                        <button mat-flat-button class="theme-button1 float-right" (click)="sendEmail()">Send</button>
                        <button mat-flat-button class="theme-button float-right mr-4" (click)="Close()" mat-dialog-close>Discard</button>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</div>