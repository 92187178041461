import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TwitterService } from '../../services/twitter.service';

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss']
})
export class TwitterComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute, private _twitterService:TwitterService) { }

  ngOnInit(): void {
    this.spinner.show();
    console.log(window.location);
    this.activatedRoute.queryParams.subscribe((data:any) => {
      
      if(data.oauth_token) {
        let oauthToken = data.oauth_token;
        let oauthVerifier = data.oauth_verifier;
        this._twitterService.getFinalAccessToken(oauthToken,oauthVerifier).subscribe((data) => {
          //SHOULD BE BACKEND API 
          console.log(data)
        },
        error => {
          console.log(error);
          window.close();
        })
      }
    },error => {
      console.log(error)
    },)
  }

}
