<div style="height: 100%;overflow-y: hidden;overflow-x: hidden;" *ngIf="isLargeDevice">
    <div class="row instruction-popup pb-2" style="height: auto;">
        <div class="col-md-12" style="text-align:end">
            <a class="profile-btn-close " (click)="closeDialog()">
                <mat-icon svgIcon="close_orange" class="profile-matIcon"></mat-icon>
            </a>
        </div>
        <div class="col-md-12">
            <h3 style="text-align:center;font-weight:bold;font-size: 18px;">Why Upgrade?</h3>
            <!-- <span>Get everything need to discoer and plan your perfect career - advamced assessment,emts. Expert Guidence, state of art
            tools and more to learn sed inum lore ipsum text
        </span> -->
        </div>

        <div class="col-md-12">
            <span class="font-15 textClr letterSpace">Get everything you need to discover and plan your perfect career – integrated psychometric
                assessment, a continuous updated library of career insights & demos, comprehensive analytics and
                reports, etc.
            </span><br>
            <h3 class="font-weight-bold mb-0 pt-2 font-15" style="line-height: 22px;">ProTeen’s 3D Awareness Engine is designed to provide a scientific, holistic and experiential approach
                to making smart career decisions in the 21st century.</h3>
        </div>
        <div class="col-md-12 breadcrumbs pt-2 mt-1">

            <div class="tabCss pr-2 d-flex justify-content-center align-items-center cursor-pointer"
                *ngFor="let arr of tabArr; let tabIndex = index"
                [ngClass]="selectedIndex == tabIndex ? 'selectedTab' : 'tabBg'" (click)="tabClick(tabIndex)">
                <div>
                    <mat-icon [ngClass]="(selectedIndex != tabIndex)?'d-inline-block':'d-none'" svgIcon={{arr.svgIcon}}>
                    </mat-icon>
                    <mat-icon [ngClass]="(selectedIndex === tabIndex)?'d-inline-block':'d-none'"
                        svgIcon={{arr.svgIcon}}{{activated}}></mat-icon>

                </div>
                <span class="font-14 letterSpace tab-txt" style="padding-left: 6px;">{{arr.title}}</span>
            </div>
        </div>
    </div>

    <div class="mainCon" style="height:calc(100% - 270px) !important;overflow-y: auto;">
        <div class="p-0" style="height: 100%;">
            <ng-container>
                <div class="col-md-12 px-3 {{selectedIndex == 0?'d-flex':'d-none'}} align-items-center boxContainer">

                    <div class="row" style="height: 100%;">
                        <div class="col-md-4">
                            <img style="width: 270px;height: 220px;" src="../../../../assets/images/whyUpgrade/Self Awareness.svg"
                                alt="">
                        </div>
                        <div class="col-md-7 ml-3 pt-3">
                            <span class="textClr font-14 letterSpace">A comprehensive psychometric assessment provides thorough illumination about an individual’s
                                abilities, interests, multiple intelligences, and personality traits, providing an awareness of self.
                                <ul class="mb-0 py-2">
                                    <li class="textClr font-14 letterSpace">Interest Test</li>
                                    <li class="textClr font-14 letterSpace">Personality Test</li>
                                    <li class="textClr font-14 letterSpace">Multiple Intelligences Test</li>
                                    <li class="textClr font-14 letterSpace">Aptitude Test</li>
                                </ul>
                                <span class="textClr font-14">All this and much more is achieved through <span class="font-weight-bold mb-0">ProTeen’s proprietary 3D Awareness
                                    Engine</span> coupled with career counseling by <span class="font-weight-bold mb-0">ProTeen’s trained Career Experts</span>.</span>

                            </span>
                            <br> <button mat-flat-button class="action-btn action-btn-text mt-3 mb-2" (click)="getStarted()">
                                Upgrade Now</button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class="col-md-12 px-3 {{selectedIndex == 1?'d-flex':'d-none'}} align-items-center" style="height: 100%;overflow-y: auto;">

                    <div class="row" style="height: 100%;">
                        <div class="col-md-4">
                            <img style="width: 270px;height: 220px;" src="../../../../assets/images/whyUpgrade/Right Fit.svg"
                                alt="">
                        </div>
                        <div class="col-md-7 ml-3 pt-3">
                            <span class="textClr font-14 letterSpace">With 26 career clusters and more than 600 careers listed in our vast libraries, various careers,
                                criteria, skills are mapped which are necessary for success in the 21st century.
                                <ul class="mb-0 py-2">
                                    <li class="textClr font-14 letterSpace">26 Career Clusters</li>
                                    <li class="textClr font-14 letterSpace">600+ Careers Demos</li>
                                    <li class="textClr font-14 letterSpace">Detailed Insights</li>
                                    <li class="textClr font-14 letterSpace">Role Plays and Activities (Coming Soon)</li>
                                    <li class="textClr font-14 letterSpace">College Finder (Coming Soon)</li>

                                </ul>
                                <span class="textClr font-14">All this and much more is achieved through <span class="font-weight-bold mb-0">ProTeen’s proprietary 3D Awareness
                                    Engine</span> coupled with career counseling by <span class="font-weight-bold mb-0">ProTeen’s trained Career Experts</span>.</span>

                            </span>
                            <br> <button mat-flat-button class="action-btn action-btn-text mt-3 mb-2" (click)="getStarted()">
                                Upgrade Now</button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class="col-md-12 px-3 {{selectedIndex == 2?'d-flex':'d-none'}} align-items-center" style="height: 100%;overflow-y: auto;">

                    <div class="row" style="height: 100%;">
                        <div class="col-md-4">
                            <img style="width: 270px;height: 220px;" src="../../../../assets/images/whyUpgrade/Career Landscape.svg"
                            alt="">
                            <!-- <div class="popup">
                                <video controls (canplay)="pauseVideo(videoplayer)" #videoPlayer>
                                    <source src="{{videoSource}}" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <ng-container *ngIf="show">
                                <div>
                                    <div class="popuptext">
                                        <div>
                                            <span class="close-button" (click)="closebutton(videoplayer)">&times;</span>
                                            <div>
                                                <label>Name:</label>
                                                <div><input id="tbname" name="yourname" /></div>
                                            </div>
                                            <div>
                                                <label>Email Address:</label>
                                                <div><input name="email" id="email" /></div>
                                            </div>
                                            <div>
                                                <label>Additional Information (optional):</label>
                                                <div><textarea rows="5" cols="46"></textarea></div>
                                                <div>
                                                    <span><input type="submit" value="Submit"
                                                            class="button button-greencolor"
                                                            (click)="closebutton(videoplayer)"></span>
                                                    <span><input type="button" value="Cancel"
                                                            class="button button-redcolor"
                                                            (click)="closebutton(videoplayer)"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->
                        </div>
                        <div class="col-md-7 ml-3 pt-3">
                            <span class="textClr font-14 letterSpace">ProTeen identifies the right profession for a student from an expansive career landscape, based
                                on individual skills, characteristics, and personalities.
                                <ul class="mb-0 py-2">
                                    <li class="textClr font-14 letterSpace">Stream / Career Fit</li>
                                    <li class="textClr font-14 letterSpace">Comprehensive Report & Roadmap</li>
                                    <li class="textClr font-14 letterSpace">Personalized Career Counselling</li>
                                </ul>
                                <span class="textClr font-14">All this and much more is achieved through <span class="font-weight-bold mb-0">ProTeen’s proprietary 3D Awareness
                                    Engine</span> coupled with career counseling by <span class="font-weight-bold mb-0">ProTeen’s trained Career Experts</span>.</span>

                            </span>
                            <br> <button mat-flat-button class="action-btn action-btn-text mt-3 mb-2" (click)="getStarted()">
                                Upgrade Now</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="col-md-12 m-0 p-0" *ngIf="isSmallDevice">
    <div id="overlay" (click)="deselectPlan()" class="{{showOverlay?'d-block':'d-none'}}"></div>
    <div class="row instruction-popup pb-2 " style="height: 270px;">
        <div class="col-md-12" style="text-align:end">
            <a class="profile-btn-close " (click)="closeDialog()">
                <mat-icon svgIcon="close_orange" class="profile-matIcon"></mat-icon>
            </a>
        </div>
        <div class="col-md-12">
            <h3 class="heading-text" style="text-align:center;font-weight:bold;font-size: 18px;">Why Upgrade?</h3>
            <!-- <span>Get everything need to discoer and plan your perfect career - advamced assessment,emts. Expert Guidence, state of art
            tools and more to learn sed inum lore ipsum text
        </span> -->
        </div>

        <div class="col-md-12" >
            <span class="font-15 textClr letterSpace text-container">Get everything you need to discover and plan your perfect career – integrated psychometric
                assessment, a continuous updated library of career insights & demos, comprehensive analytics and
                reports, etc.
            </span><br>
            <h3 class="font-weight-bold mb-0 pt-2 font-15 heading-container" style="line-height: 22px;">ProTeen’s 3D Awareness Engine is designed to provide a scientific, holistic and experiential approach
                to making smart career decisions in the 21st century.</h3>
        </div>

    </div>
    <div class="col-md-12 p-0 m-0" style="margin-top:8.2rem !important;">
        <mat-accordion>

            <mat-expansion-panel class="accordion-box " (afterCollapse)="scrollToTop(0,false)"  (afterExpand)="scrollToTop(0,true)" id="accordian_0">
              <mat-expansion-panel-header class="plan-header {{tabArr[0].activated?'active-iteam':''}}">
                <mat-panel-title >
                    <div class="tabCss pr-2 d-flex  align-items-center cursor-pointer">


                    <div>
                        <mat-icon *ngIf="!tabArr[0].activated" svgIcon={{tabArr[0].svgIcon}}>
                        </mat-icon>
                        <mat-icon *ngIf="tabArr[0].activated" svgIcon={{tabArr[0].svgIcon}}{{activatedd}}></mat-icon>

                    </div>
                    <span class="font-14 letterSpace tab-txt-mob" style="padding-left: 6px;">{{tabArr[0].title}}</span>
                </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngIf="selectedIndex == 0 ">
                <div class="col-md-12 px-3 d-flex align-items-center boxContainer">

                    <div class="row" style="height: 100%;scroll-behavior: auto;">
                        <div class="col-md-12 d-flex justify-content-center">
                            <img style="width: 180px;height: 180px;" src="../../../../assets/images/whyUpgrade/Self Awareness.svg"
                                alt="">
                        </div>
                        <div class="col-md-12  pt-3">
                            <span class="textClr font-14 letterSpace">A comprehensive psychometric assessment provides thorough illumination about an individual’s
                                abilities, interests, multiple intelligences, and personality traits, providing an awareness of self.
                                <ul class="mb-0 py-2">
                                    <li class="textClr font-14 letterSpace">Interest Test</li>
                                    <li class="textClr font-14 letterSpace">Personality Test</li>
                                    <li class="textClr font-14 letterSpace">Multiple Intelligences Test</li>
                                    <li class="textClr font-14 letterSpace">Aptitude Test</li>
                                </ul>
                                <span class="textClr font-14">All this and much more is achieved through <span class="font-weight-bold mb-0">ProTeen’s proprietary 3D Awareness
                                    Engine</span> coupled with career counseling by <span class="font-weight-bold mb-0">ProTeen’s trained Career Experts</span>.</span>

                            </span>
                            <br> <button mat-flat-button class="action-btn action-btn-text mt-3 mb-2 w-100" (click)="getStarted()">
                                Upgrade Now</button>
                        </div>
                    </div>
                </div>
            </ng-container>
            </mat-expansion-panel>

            <mat-expansion-panel class="accordion-box " (afterCollapse)="scrollToTop(1,false)" (afterExpand)="scrollToTop(1,true)" id="accordian_1">
                <mat-expansion-panel-header class="plan-header {{tabArr[1].activated?'active-iteam':''}}">
                  <mat-panel-title>
                    <div class="tabCss pr-2 d-flex  align-items-center cursor-pointer">
                     <div>
                         <mat-icon *ngIf="!tabArr[1].activated" svgIcon={{tabArr[1].svgIcon}}>
                         </mat-icon>
                         <mat-icon *ngIf="tabArr[1].activated" svgIcon={{tabArr[1].svgIcon}}{{activatedd}}></mat-icon>

                     </div>
                     <span class="font-14 letterSpace tab-txt" style="padding-left: 6px;">{{tabArr[1].title}}</span>
                 </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="col-md-12 px-3 d-flex align-items-center" style="height: 100%;">

                    <div class="row" style="height: 100%;">
                        <div class="col-md-12 d-flex justify-content-center">
                            <img style="width: 180px;height: 180px;" src="../../../../assets/images/whyUpgrade/Right Fit.svg"
                                alt="">
                        </div>
                        <div class="col-md-12">
                            <span class="textClr font-14 letterSpace">With 26 career clusters and more than 600 careers listed in our vast libraries, various careers,
                                criteria, skills are mapped which are necessary for success in the 21st century.
                                <ul class="mb-0 py-2">
                                    <li class="textClr font-14 letterSpace">26 Career Clusters</li>
                                    <li class="textClr font-14 letterSpace">600+ Careers Demos</li>
                                    <li class="textClr font-14 letterSpace">Detailed Insights</li>
                                    <li class="textClr font-14 letterSpace">Role Plays and Activities (Coming Soon)</li>
                                    <li class="textClr font-14 letterSpace">College Finder (Coming Soon)</li>

                                </ul>
                                <span class="textClr font-14">All this and much more is achieved through <span class="font-weight-bold mb-0">ProTeen’s proprietary 3D Awareness
                                    Engine</span> coupled with career counseling by <span class="font-weight-bold mb-0">ProTeen’s trained Career Experts</span>.</span>

                            </span>
                            <br> <button mat-flat-button class="action-btn action-btn-text mt-3 mb-2 w-100" (click)="getStarted()">
                                Upgrade Now</button>
                        </div>
                    </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel class="accordion-box " (afterCollapse)="scrollToTop(2,false)"  (afterExpand)="scrollToTop(2,true)" id="accordian_2">
                <mat-expansion-panel-header class="plan-header {{tabArr[2].activated?'active-iteam':''}}">
                  <mat-panel-title>
                    <div class="tabCss pr-2 d-flex  align-items-center cursor-pointer">
                     <div>
                         <mat-icon *ngIf="!tabArr[2].activated" svgIcon={{tabArr[2].svgIcon}}>
                         </mat-icon>
                         <mat-icon *ngIf="tabArr[2].activated" svgIcon={{tabArr[2].svgIcon}}{{activatedd}}></mat-icon>

                     </div>
                     <span class="font-14 letterSpace tab-txt"  style="padding-left: 6px;">{{tabArr[2].title}}</span>
                 </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="col-md-12 px-3 d-flex align-items-center" style="height: 100%;">

                    <div class="row" style="height: 100%;">
                        <div class="col-md-12 d-flex justify-content-center">
                            <img style="width: 180px;height: 180px;" src="../../../../assets/images/whyUpgrade/Career Landscape.svg"
                            alt="">
                            <!-- <div class="popup">
                                <video controls (canplay)="pauseVideo(videoplayer)" #videoPlayer>
                                    <source src="{{videoSource}}" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <ng-container *ngIf="show">
                                <div>
                                    <div class="popuptext">
                                        <div>
                                            <span class="close-button" (click)="closebutton(videoplayer)">&times;</span>
                                            <div>
                                                <label>Name:</label>
                                                <div><input id="tbname" name="yourname" /></div>
                                            </div>
                                            <div>
                                                <label>Email Address:</label>
                                                <div><input name="email" id="email" /></div>
                                            </div>
                                            <div>
                                                <label>Additional Information (optional):</label>
                                                <div><textarea rows="5" cols="46"></textarea></div>
                                                <div>
                                                    <span><input type="submit" value="Submit"
                                                            class="button button-greencolor"
                                                            (click)="closebutton(videoplayer)"></span>
                                                    <span><input type="button" value="Cancel"
                                                            class="button button-redcolor"
                                                            (click)="closebutton(videoplayer)"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->
                        </div>
                        <div class="col-md-12 letterSpace">
                            <span class="textClr font-14 letterSpace">ProTeen identifies the right profession for a student from an expansive career landscape, based
                                on individual skills, characteristics, and personalities.
                                <ul class="mb-0 py-2">
                                    <li class="textClr font-14 letterSpace">Stream / Career Fit</li>
                                    <li class="textClr font-14 letterSpace">Comprehensive Report & Roadmap</li>
                                    <li class="textClr font-14 letterSpace">Personalized Career Counselling</li>
                                </ul>
                                <span class="textClr font-14">All this and much more is achieved through <span class="font-weight-bold mb-0">ProTeen’s proprietary 3D Awareness
                                    Engine</span> coupled with career counseling by <span class="font-weight-bold mb-0">ProTeen’s trained Career Experts</span>.</span>

                            </span>
                            <br> <button mat-flat-button class="action-btn action-btn-text mt-3 mb-2 w-100" (click)="getStarted()">
                                Upgrade Now</button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

        </mat-accordion>


    </div>
</div>
