import { environment } from './../../../../environments/environment';
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { StudentService } from '../../../student/services/student.service';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopupComponent implements OnInit {
  public attempts:any = undefined
  public allowAttempts:any = 3;
  alertMsgFlag: boolean = false;
  constructor(public dialog: MatDialogRef<CustomPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _router: Router, private _authService: AuthService,private studentService:StudentService){
  }

  ngOnInit() {
    this.allowAttempts = environment.assessmentAttempts,
    this.alertMsgFlag = this.data.alertMsgFlag;
    if(this.data.attemptsData)
      this.attempts = this.allowAttempts - this.data.attemptsData.totalAttempts;
  }
  onConfirm(){
    //this._router.navigate(['/assessment']);
    if(this.data.submit) {
      this.dialog.close(true);
    }
    else {
      this.dialog.close(this.data.nextRoute)
    }
    
  }

  closeDialog(): void {
    this._authService.assessmentHighlight = true;
    this.studentService.highlightLeftMenu('/assessment','child')
    this.dialog.close();
  }
}

