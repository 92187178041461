import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { StudentService } from 'src/app/student/services/student.service';

const httpOptions = {
  headers: new HttpHeaders({
  })
};

@Injectable({
  providedIn: 'root'
})
export class LinkedinService {
  apiUrl = '';
  BASE_API_URL = 'https://www.linkedin.com/oauth/v2';
  backendApi = environment.ApiConfig.student;
  userDetails:any;
  public customSubject = new Subject<any>();
  constructor(private http:HttpClient,
    private _snackBar:MatSnackBar,
    private _studentService:StudentService
    ) {
    this.apiUrl = this.BASE_API_URL;
  }

  customObservable = this.customSubject.asObservable();

  getAuthCodeURL() {    
    let params = {
      response_type:"code",
      client_id:environment.social_media.linkedin.client_id,
      //redirect_uri:'https://develop.d3bf9b4lgay1rh.amplifyapp.com/student/profile',
      redirect_uri:window.location.origin + '/shared/linkedin',
      state:this.randomString(),
      scope:'r_liteprofile r_emailaddress'
    }
    return `${this.BASE_API_URL}/authorization?response_type=${params.response_type}&client_id=${params.client_id}&state=${params.state}&scope=${params.scope}&redirect_uri=${params.redirect_uri}`
  }

  randomString() {  
        //define a variable consisting alphabets in small and capital letter  
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz1234567890";  
            
          //specify the length for the new string  
      var lenString = 7;  
      var randomstring = '';  

          //loop to select a new character in each iteration  
      for (var i=0; i<lenString; i++) {  
      var rnum = Math.floor(Math.random() * characters.length);  
      randomstring += characters.substring(rnum, rnum+1);  
      }  
      return randomstring;
   }
   
   getAccessTokenFromCode(code) {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Connection': 'keep-alive'
      })
    };
    let params = {
      "grant_type":"authorization_code",
      code:code,
      redirect_uri:window.location.origin + '/shared/linkedin',
      client_id:environment.social_media.linkedin.client_id,
      client_secret:environment.social_media.linkedin.client_secret,
    }
    return this.http.post(`${this.backendApi}/student/social/linkedin`,params,httpOptions)
   }

   showSnackError(errorMsg = 'Something went wrong') {
    this._snackBar.open(errorMsg, 'x', {
        duration: 3 * 1000,
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
   }

   showSnackSuccess() {
    this._snackBar.open("You have successfully linked your social media account with ProTeen.", 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: ['success-snackbar']
    });    
  }

  unlink() {
    if(this.userDetails.social_media && this.userDetails.social_media.length > 0){
        this.userDetails.social_media.forEach((element,index) => {
            if (element.social_media == "LinkedIn"){
              this.userDetails.social_media.splice(index,1)
            }
        });
    }
    let mediaDetails = {
      social_media:this.userDetails.social_media
    }
    this._studentService.updateStudentDetails(mediaDetails, 'basic').subscribe(
        (data:any) => {
            if(data.success){
                this.customSubject.next();//call basic tab get call
                this._snackBar.open('You have successfully unlinked your social media account with ProTeen.', 'x', {
                    duration: 3 * 1000,
                    verticalPosition: 'bottom',
                    panelClass: ['success-snackbar']
                });
            }else{
                this._snackBar.open('Something went wrong.', 'x', {
                    duration: 3 * 1000,
                    verticalPosition: 'bottom',
                    panelClass: ['error-snackbar']
                });
            }
        },
        err => {
            this._snackBar.open('Something went wrong.', 'x', {
                duration: 3 * 1000,
                verticalPosition: 'bottom',
                panelClass: ['error-snackbar']
            });
            console.log(err);
        }
    );
        
    
}

}
