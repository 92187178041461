import { ElementRef,Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxNumberLength]'
})
export class MaxNumberLengthDirective {
  @Input() numMaxLength : any;
  constructor(private ref: ElementRef, private ngControl:NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    if (event.target.value.length >= this.numMaxLength) {
      this.ref.nativeElement.value = event.target.value.substring(0,this.numMaxLength)
      this.ngControl.control.setValue = event.target.value.substring(0,this.numMaxLength)
      //console.log(this.ref.nativeElement.value)
    }
  }
}
