import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Content-Type': 'application/json',
    'Authorization': '',
  })
};

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private apiUrl:string;

  constructor(private http:HttpClient) { 
    const BASE_API_URL = environment.ApiConfig.student;
    this.apiUrl = BASE_API_URL;
  }

  fetchCacheData(section) {
    let userSub = sessionStorage.getItem('userSub');
    return this.http.get(`${this.apiUrl}/student/assesment/responses-cache?key=${userSub}_${section}`)
  }

  saveUrlPreview(url,data) {
    let reqBody = {
      key:url,
      value:JSON.stringify(data)
    }
    return this.http.post(`${this.apiUrl}/student/assesment/responses-cache`,reqBody)
  }

  fetchUrlPreview(url) {
    return this.http.get(`${this.apiUrl}/student/assesment/responses-cache?key=${url}`)
  }

  postCacheData(dataToPost, section) {
    let userSub = sessionStorage.getItem('userSub');
    let reqBody = {
      key:`${userSub}_${section}`,
      value:JSON.stringify(dataToPost)
    }
    console.log('reqBody====================================', reqBody)
    return this.http.post(`${this.apiUrl}/student/assesment/responses-cache`, reqBody)
    
  }

  postCacheDataJSON(dataToPost, section) {
    let userSub = sessionStorage.getItem('userSub');
    let reqBody = {
      key:`${userSub}_${section}`,
      value:dataToPost
    }
    console.log('reqBody====================================', reqBody)
    return this.http.post(`${this.apiUrl}/student/assesment/responses-cache`, reqBody)
    
  }

  deleteCacheData(section) {
    let userSub = sessionStorage.getItem('userSub');
    let httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': '',
      }),
      body:{key:`${userSub}_${section}`}
    };
    return this.http.delete(`${this.apiUrl}/student/assesment/responses-cache`,httpOptions)
  }

  tryParseJSON (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
  };

}
