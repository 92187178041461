<div class="overlay" *ngIf="_psychometricService.showOverlayPersonality">
    <div class="row pr-3 pt-2 d-flex align-item-center justify-content-end" style="height: 60px;">

    </div>
    <div class="row" style="height:calc(100% - 70px)">
        <div class="col-2 noMobile">
        </div>
        <div class="col-12 col-md-10 col-lg-10 pl-4 pr-4" style="height: 100%;">
            <div class=" pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between" style="height: 60px;">
                <h3 class="tab-heading letter-spacing" translate="no">Personality Sample – Let’s first see how the test works.
                </h3>
                <img class="cursor-pointer pt-2" *ngIf="!showSkipCloseBtn"
                    src="../../assets/svg/assessments/tour/skipbtn.png" style="height: 50px;" (click)="closeTour()">
            </div>
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 pl-0 pr-0 assessment-profile-section"
                style="height:calc(100% - 60px);overflow-y: auto;overflow-x: hidden;">
                <div class="pb-2" style="display:flex;">
                    <h4 style="color: white;margin-bottom: 0px;" translate="no">You have to select one of the 5 options for each statement that best represents you.</h4>
                </div>
                <div class="row" id="content">
                    <ng-container>
                        <div class="mainContainer pt-1" style="height: calc(100% - 70px)" id="scrolling_div">
                            <div class="row main-question" id="content">
                                <ng-container *ngFor="let question of questions;let questionIndex = index">
                                    <div class="col-sm-12">
                                        <div class="card pb-3" id="element_div{{questionIndex}}"
                                            [ngClass]="{ 'noOpacity': (selectedQuestion == questionIndex || currentIndex == questionIndex),'questionOpacity': selectedQuestion != questionIndex }">
                                            <div class="card-body custom-cardBody" style="width: 100%;">
                                                <div class="d-flex font-weight-bold flex-column mt-0 pt-3 pl-3"
                                                    style="margin-bottom: 0px !important;">
                                                    <span class="optionTitle pt-1 pb-2" translate="no">{{question.title}}</span>
                                                    <div class="d-flex justify-content-between" style="width: 50%;">
                                                        <img class="cursor-pointer imgClass"
                                                            *ngFor="let img of imgArr;let imgI = index" [ngStyle]="{'padding-top': img.padding}"
                                                            [src]='question.answer == imgI ? img.src1 : img.src'>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </ng-container>
                </div>
                <img class="cursor" #cursori src="../../assets/svg/assessments/cursorp.svg" alt="">
                <div class="pt-3 d-flex align-items-center justify-content-end pr-5">

                </div>
            </div>
        </div>
    </div>
</div>