import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-disclaimer-popup',
  templateUrl: './disclaimer-popup.component.html',
  styleUrls: ['./disclaimer-popup.component.scss']
})
export class DisclaimerPopupComponent implements OnInit {
  
  isLargeDevice:boolean;
  isSmallDevice:boolean;
 
  constructor(public dialog: MatDialogRef<DisclaimerPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private _router: Router, 
    private _authService: AuthService,
    private breakpointObserver:BreakpointObserver){
  }

  ngOnInit() {
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isLargeDevice = true;
        this.isSmallDevice = false;
      }
    });
    
  }

 

  closeDialog(): void {
    this.dialog.close();
  }
}

