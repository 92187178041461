import { StudentService } from './../../../student/services/student.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { InstagramService } from '../../services/instagram.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private _studentService:StudentService,
    private _instagramService:InstagramService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe((data:any) => {
      
      if(data.code) {
        console.log(data);
        this._instagramService.requestAccessToken(data.code).subscribe((dataResponse:any) => {
          console.log(dataResponse);
          dataResponse = JSON.parse(dataResponse)
          let mediaArray = JSON.parse(localStorage.getItem('social'));
          let saved = false;
          mediaArray.forEach((element) => {
            if(element.social_media == 'Instagram') {
              element.access_token = dataResponse.access_token;
              element.expiry = dataResponse.expires_in;
              saved = true;
            }
          })
          //if instagram doesnt exist
          if(!saved) {
            let obj = {
              social_media:'Instagram',
              access_token:dataResponse.access_token,
              expiry:dataResponse.expires_in
            }
            mediaArray.push(obj)
          }
          
          this._studentService.updateStudentDetails({social_media:mediaArray},'basic').subscribe((response:any) => {
            if(response.success) {
              localStorage.setItem('instagram_linked','1');
              window.close();
            }
          }) 
        },
        error => {
          window.close();
          console.log(error);
        }
        )
      }
    },error => {
      window.close();
      console.log(error)
    },)
  }

  


}
