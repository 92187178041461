import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LinkedinService } from '../../services/linkedin.service';
import { StudentService } from 'src/app/student/services/student.service';

@Component({
  selector: 'app-linkedin',
  templateUrl: './linkedin.component.html',
  styleUrls: ['./linkedin.component.scss']
})
export class LinkedinComponent implements OnInit, AfterViewInit {
  windowData;
  success = false;
  linkedinError = false;
  constructor(private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private _linkedinService:LinkedinService,
    private _studentService:StudentService) { }

  ngOnInit(): void {
    this.spinner.show();
    console.log(window.location)
    this.activatedRoute.queryParams.subscribe(data => {
      if(data.error) {
        //window.close();
        console.log(data)
        this.spinner.hide();
      }
      if(data.code) {
        this._linkedinService.getAccessTokenFromCode(data.code).subscribe((statusResponse:any) => {
          console.log(statusResponse);
          statusResponse = JSON.parse(statusResponse);
          let mediaArray = JSON.parse(localStorage.getItem('social'));
          //console.log(mediaArray)
          let saved = false;
          mediaArray.forEach((element) => {
            if(element.social_media == 'LinkedIn') {
              element.access_token = statusResponse.access_token;
              element.expiry = statusResponse.expires_in;
              saved = true;
            }
          })
          //if linkedin doesnt exist
          if(!saved) {
            let obj = {
              social_media:'LinkedIn',
              access_token:statusResponse.access_token,
              expiry:statusResponse.expires_in
            }
            //console.log(obj)
            mediaArray.push(obj)
          }
          //console.log(mediaArray)
          this._studentService.updateStudentDetails({social_media:mediaArray},'basic').subscribe((response:any) => {
            if(response.success) {
              localStorage.setItem('linkedin_linked','1');
              window.close();
              //this._facebookService.showSnackSuccess();
            }
          }) 
        },
        error => {
          window.close();
          console.log(error)
         // window.close();
        }
        )

      }
    })
  }
  ngAfterViewInit() {
    console.log(window.location)
  }
}
