import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oridinal'
})
export class OridinalDatePipePipe implements PipeTransform {

  transform(value: Date): string {
    if (!value) {
      return '';
    }
    value = new Date(value);
   let months= ["January","February","March","April","May","June","July",
           "August","September","October","November","December"]
    return `${value.getDate()}${this.nth(value.getDate())} ${months[value.getMonth()]} ${value.getFullYear()}`;
  }

 nth(d) {
  
  if (d > 3 && d < 21) return 'th'; 
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

}
