import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})


export class SettingsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };


  myDate = new Date()
  date : any;
  private apiUrl:string;
  private apiUrlStud:string;
  constructor(private http:HttpClient,private _authService:AuthService) {
    const BASE_API_URL = environment.ApiConfig.settings;
    const BASE_API_URL_Student = environment.ApiConfig.student ;

    this.apiUrl = `${BASE_API_URL}/settings`;
    this.apiUrlStud = `${BASE_API_URL_Student}/student`;


    this._authService.getUserDetails().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': data.user.signInUserSession.idToken.jwtToken,
        })

      };
      //this.http.options(this.httpOptions);


    })




  }


  getCurrentDate(){
   return  moment().format("YYYY-MM-DD HH:mm:ss");

  }
  getHobbies() {
    return this.http.get(`${this.apiUrl}/hobbies`, this.httpOptions);
  }
  getLanguages() {
    return this.http.get(`${this.apiUrl}/languages`);
  }

  getEcas() {
    return this.http.get(`${this.apiUrl}/extra-curricular-activities`);
  }

  getAspirations() {
    return this.http.get(`${this.apiUrl}/aspirations`, this.httpOptions);
  }

  getCompetitiveExams() {
    return this.http.get(`${this.apiUrl}/competitive-exams`);
  }

  getAchievementLevels() {
    return this.http.get(`${this.apiUrl}/achievements/level`);
  }

  getAchievementFields() {
    return this.http.get(`${this.apiUrl}/achievements/fields`, this.httpOptions);

  }

  getSchoolList() {
    return this.http.get(`${this.apiUrl}/education/schools`, this.httpOptions);
  }

  getCollegesList() {
    return this.http.get(`${this.apiUrl}/education/colleges`, this.httpOptions);
  }

  getCollegesListOnSearch(searchKey,classId) {
    if(searchKey==1){
      // return this.http.get(`${this.apiUrl}/education/colleges`);
      return this.http.get(`${this.apiUrlStud}/profile/education?class_id=${classId}&type=schools`, this.httpOptions);


    }else{
      return this.http.get(`${this.apiUrlStud}/profile/education?class_id=${classId}&type=schools&q=${searchKey}`, this.httpOptions);

    //  return this.http.get(`${this.apiUrl}/education/colleges?q=${searchKey}`);

    }
  }

  getUniversitiesList() {
    return this.http.get(`${this.apiUrl}/education/universities`, this.httpOptions);
  }

  getBoardsList(){
    return this.http.get(`${this.apiUrl}/education/boards`, this.httpOptions);
  }

  updateCareerCount(uuid,career_id,cluster_id) {
    let patchData = {
      uuid:uuid,
      id:career_id,
      cluster_id:cluster_id
    }
    return this.http.patch(`${this.apiUrl}/dashboard/users/student/explore`,patchData, this.httpOptions);
  }
  updateCluster(uuid,cluster_id){
    let patchData = {
      uuid:uuid,
      cluster_id:cluster_id
    }
    return this.http.patch(`${this.apiUrl}/dashboard/users/student/explore`, patchData, this.httpOptions);
  }
  getCluster(uuid) {
    return this.http.get(`${this.apiUrl}/dashboard/users/student/explore?uuid=${uuid}`, this.httpOptions);
  }

  getCareerCount(uuid) {
    return this.http.get(`${this.apiUrl}/dashboard/users/student/explore?uuid=${uuid}`, this.httpOptions);
  }


  // for family profile
   getEducationForFamily(){
    return this.http.get(`${this.apiUrl}/parent/education`, this.httpOptions);
  }

  getOccupationForFamily(){
    return this.http.get(`${this.apiUrl}/occupations`, this.httpOptions);
  }


  getStudentEducationClass(){
    return this.http.get(`${this.apiUrlStud}/profile/education?type=classes`, this.httpOptions);
  }

  getStudentEducationStream(classId){
    return this.http.get(`${this.apiUrlStud}/profile/education?class_id=${classId}&type=streams`, this.httpOptions);
  }

  getStudentEducationSubject(classid,streamId){

    return this.http.get(`${this.apiUrlStud}/profile/education?class_id=${classid}&stream_course_id=${streamId}&type=subjects`, this.httpOptions);
  }

  getSchoolListForEducation(classId) {
    return this.http.get(`${this.apiUrlStud}/profile/education?class_id=${classId}&type=schools`, this.httpOptions);
  }

  getBoardListforEducation(classId) {
    return this.http.get(`${this.apiUrlStud}/profile/education?class_id=${classId}&type=boards`, this.httpOptions);
  }





  // for education post API'S


  addExamDetails(userAttributes) {
    userAttributes.created_at =this.getCurrentDate();
    userAttributes.updated_at =this.getCurrentDate();
        return this.http.post(`${this.apiUrl}/competitive-exams`, userAttributes, this.httpOptions);
  }

  addSchoolDetails(school) {
    school.created_at =this.getCurrentDate();
    school.updated_at =this.getCurrentDate();
    return this.http.post(`${this.apiUrl}/education/schools`, school, this.httpOptions);
}


addcollegesDetails(colleges) {
  colleges.created_at =this.getCurrentDate();
  colleges.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/education/colleges`, colleges, this.httpOptions);
}

addBoardDetails(board) {
  board.created_at =this.getCurrentDate();
  board.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/education/boards`, board, this.httpOptions);
}

adduniversitiesDetails(universities) {
  universities.created_at =this.getCurrentDate();
  universities.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/education/universities`, universities, this.httpOptions);
}


// for family post api

addOccupationsDetails(userAttributes) {
  userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();
  console.log('userAttributes----', userAttributes)

  return this.http.post(`${this.apiUrl}/occupations`, userAttributes, this.httpOptions);
}

addParentEducationDetails(userAttributes) {
  userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/parent/education`, userAttributes, this.httpOptions);
}

addAchievementsFields(userAttributes) {
  userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/achievements/fields`, userAttributes, this.httpOptions);
}

addAchievementsLeavel(userAttributes) {
  userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/achievements/level`, userAttributes, this.httpOptions);
}

addLanguages(userAttributes) {
  userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();
  return this.http.post(`${this.apiUrl}/languages`, userAttributes, this.httpOptions);
}


  // for education PACH API'S

  updateCollegeDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/education/colleges/${uuid}`,updatedData, this.httpOptions);
  }

  updateSchoolDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/education/schools/${uuid}`,updatedData, this.httpOptions);
  }

  updateCompetitiveExamsDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/competitive-exams/${uuid}`,updatedData, this.httpOptions);
  }

  updateParentEducationDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/parent/education/${uuid}`,updatedData, this.httpOptions);
  }

  updateUniversitiesDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/education/universities/${uuid}`,updatedData, this.httpOptions);
  }

  updateBoardDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/education/boards/${uuid}`,updatedData, this.httpOptions);
  }

  updateOccupationsDetails(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    console.log('updatedData----', updatedData)

    return this.http.patch(`${this.apiUrl}/occupations/${uuid}`,updatedData, this.httpOptions);
  }

  updateAchievementsFields(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/achievements/fields/${uuid}`,updatedData, this.httpOptions);
  }

  updateAchievementsLevel(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/achievements/level/${uuid}`,updatedData, this.httpOptions);
  }

  updateLanguages(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/languages/${uuid}`,updatedData, this.httpOptions);
  }

  deleteCollegeDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/education/colleges/${uuid}`, this.httpOptions);
  }

  deleteLanguages(uuid) {
    return this.http.delete(`${this.apiUrl}/languages/${uuid}`, this.httpOptions);
  }

  deleteSchoolDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/education/schools/${uuid}`, this.httpOptions);
  }



  deleteAchievementsFields(uuid) {
    return this.http.delete(`${this.apiUrl}/achievements/fields/${uuid}`, this.httpOptions);
  }

  deleteAchievementsLevel(uuid) {
    return this.http.delete(`${this.apiUrl}/achievements/level/${uuid}`, this.httpOptions);
  }
  deleteOccupationsDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/occupations/${uuid}`, this.httpOptions);
  }

  deleteCompetitiveExamsDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/competitive-exams/${uuid}`, this.httpOptions);
  }

  deleteUniversitiesDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/education/universities/${uuid}`, this.httpOptions);
  }

  deleteBoardDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/education/boards/${uuid}`, this.httpOptions);
  }

  deleteParentEducationDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/parent/education/${uuid}`, this.httpOptions);
  }



}
