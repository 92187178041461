import { Injectable } from '@angular/core';

//import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  ENCRYPTION_KEY = 'fTjWnZr4u7x!A%C*';
  DISABLED = false;
  constructor() { }

  encrypt(text) {
    if(this.DISABLED) {
      return text;
    }
    return btoa(text)
    //return CryptoJS.AES.encrypt(text,this.ENCRYPTION_KEY).toString();
    
  }

  decrypt(ciphertext) {
    if(this.DISABLED) {
      return ciphertext;
    }
    //let bytes  = CryptoJS.AES.decrypt(ciphertext, this.ENCRYPTION_KEY);
    //return bytes.toString(CryptoJS.enc.Utf8);
    return atob(ciphertext)
    
    
    
  }
}
