import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './helpers/auth.guard';
import { FeatureGuard } from './helpers/feature.guard';
import { RequestInterceptor } from './helpers/request.interceptor';
import { LocalstorageService } from './services/localstorage.service';
import { TimeoutService } from './services/timeout.service';
import { SharedModule } from './shared/shared.module';
import { SidenavService } from './student/services/sidenav.service';
import { PersonalityTourGuideComponent } from './personality-tour-guide/personality-tour-guide.component';
import { AicteComponent } from './components/aicte/aicte.component';
import { AutologinComponent } from './components/autologin/autologin.component';
import { AutoSocialLoginComponent } from './components/auto-social-login/auto-social-login.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export function HttpLoaderFactory(http: HttpClient){
}


/* Configure Amplify resources */
// Amplify.configure(awsconfig);

@NgModule({
  exports: [],
  declarations: [
    AppComponent,
    PersonalityTourGuideComponent,
    AicteComponent,
    AutologinComponent,
    AutoSocialLoginComponent,    
    // NavbarComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SharedModule,
    //NgxsModule.forRoot([AssessmentState]),
    //NgxsModule.forRoot(),
    //NgxsReduxDevtoolsPluginModule.forRoot(),
    //NgxsStoragePluginModule.forRoot(),
    //FontAwesomeModule,
    //CapitalizeFirstDirective,
    //AdminModule,
    /* configure app with AmplifyUIAngularModule */
    AmplifyUIAngularModule,
    // AmplifyAngularModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    
  ],
  providers: [
    AuthGuard,
    FeatureGuard,
    BnNgIdleService,
    TimeoutService,
    SidenavService,
    LocalstorageService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    // AmplifyService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
