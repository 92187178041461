<div *ngIf="!bMobileView">
    <p-table #dt selectionMode="multiple" binary="true" lazy="true" (onLazyLoad)="updateData($event)"
        (onFilter)="applyFilter($event)" [(selection)]="selectedData" stateStorage="local"
        stateKey="{{tableFor + '-filters'}}" [autoLayout]="true" [value]="tableData" [responsive]="true" [rows]="10"
        [globalFilterFields]="searchData" dataKey="id" [reorderableColumns]="true" exportFilename="exportedData"
        [columns]="columnHeader" editMode="row" styleClass="p-datatable-gridlines" scrollable="true"
        resizableColumns="true" [rowsPerPageOptions]="[5,10,15,20,25,30,35,40,45,50]">

        <!--filters start-->
        <ng-template pTemplate="caption" *ngIf="filterFlag">
            <div class="row pt-0">
                <div class="col-md-7 mb-0 pb-0">
                    <h3 class="totalpages mt-1 mb-0 pt-0 pb-0"><span
                            class="mr-2">{{tableTitle}}</span>({{tableData.length}}
                        of
                        {{tableTotalCount}})</h3>
                </div>
            </div>
            <div class="row pr-2">
                <div class="col-md-12 d-flex justify-content-between pl-0 pr-0">
                    <div class="row col-md-6">
                        <div class="col-md-4">
                            <p-dropdown [autoWidth]="false" [style]="{'minWidth':'100%'}" [options]="bulkAction"
                                [(ngModel)]="seletedBulkAction" placeholder="Bulk Action" optionLabel="name"
                                [showClear]="false"></p-dropdown>


                            <!-- <ng-select appDropdown [placeholder]="'Bulk Action'"
                        class="single-select family-select containerW orangeDropdown" appearance="outline"
                        [clearOnBackspace]="true" [clearable]="true" [searchable]="false" [(ngModel)]="seletedEditandDeleted" dropdownPosition="bottom"
                        (change)="selectChangeBulk($event)" bindLabel="item_text">
                        <ng-option *ngFor="let dropItem of dropdownList" [value]="dropItem.item_text" #bulkOption
                            id="bulkOption">
                            <div class="d-flex">
                                <div>
                                    <img class="bulklist-icon" src="{{dropItem.iconOrange}}" />
                                </div>
                                <div>
                                    {{dropItem.item_text}}
                                </div>
                            </div>
                        </ng-option>
                    </ng-select> -->

                        </div>
                        <div class="col-md-2" style="margin-left: -20px;">
                            <!-- <button (click)="submitBulk()">go</button> -->
                            <button style="height: 38px;margin-top: 0px !important;margin-right:5px"
                                class="theme-button1 alignBtn action-btn-text btn-sm" (click)="submitBulk()">
                                <div class="d-flex">

                                    <div class="customSmTxt">Go</div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 px-0" style="margin-left: -20px;">
                            <button style="height: 38px;margin-top: 0px !important;margin-right:5px"
                                class="theme-button alignBtn action-btn-text btn-sm" (click)="onClearFilter(dt)">
                                <div class="d-flex">

                                    <div class="customSmTxt">Clear Filters</div>
                                </div>
                            </button>
                        </div>

                    </div>


                    <!-- <div class="col-md-2 d-flex-center mt-2 ml-0" >
                   <p-multiSelect [displaySelectedLabel]="false" class="mx-2" scrollHeight="300px" (onChange)="toggleValue($event)" [(ngModel)]="selectedColumns" [showToggleAll]="false" [options]="dropdownSource" defaultLabel="Filter By" optionLabel="header" class="column-filter mr-4">
                    <ng-template pTemplate="header">
                        <div (click)="resetFilters(dt)" style="opacity:0.7" class="w-100 d-flex justify-content-between mt-2 reset-all-btn">
                            <span>Reset All Filters</span>
                            <i class="fa fa-refresh pull-right"></i>
                        </div>
                    </ng-template>
                    <ng-template let-column pTemplate="item">
                            <span *ngIf="column.dropdownDisplay" style="font-size:12px" class="w-100">
                                {{column.header}}
                            </span>
                    </ng-template>
                </p-multiSelect>
            </div> -->


                </div>
            </div>
            <!--div class="row pr-2">
            <div class="col-md-12 pl-0">
                <hr class="custom-hr" />
            </div>
        </div>
        <div class="row pr-2">
            <div class="col-md-12 d-flex justify-content-between pl-0 pr-0">
            <div>
            <div class="col-md-2">
            </div>
            </div>
            <div class="d-flex">
            <div class="" style="padding-right: 10px;">
            </div>
            <div class="pr-0">
                <span class="p-input-icon-right   p-search">
                    <i class="pi pi-search" style="color: #f36d39;"></i>
                    <input pInputText type="text" class="inputPlaceholder"
                        style="border: 1px solid #AEAEAE !important;outline:none;border-radius: 5px;padding: 6px; width: 100%;"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
            </div>
            </div>

        </div>
        </div-->
            <!-- <div class="row pr-2">
            <div class="col-5">
                <ng-select appDropdown [placeholder]="'Bulk Action'"
                        class="single-select family-select containerW orangeDropdown" appearance="outline"
                        [clearOnBackspace]="true" [clearable]="true" [searchable]="false" [(ngModel)]="seletedEditandDeleted" dropdownPosition="bottom"
                        (change)="selectChangeBulk($event)" bindLabel="item_text">
                        <ng-option *ngFor="let dropItem of dropdownList" [value]="dropItem.item_text" #bulkOption
                            id="bulkOption">
                            <div class="d-flex">
                                <div>
                                    <img class="bulklist-icon" src="{{dropItem.iconOrange}}" />
                                </div>
                                <div>
                                    {{dropItem.item_text}}
                                </div>
                            </div>
                        </ng-option>
                    </ng-select>
            </div>
        </div> -->
            <div class="row mt-2">
                <div *ngIf="!filterDisplay || filterDisplay.length == 0" class="col-12">
                    <span class="mr-1">Filters: None</span>
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-between" *ngIf="filterDisplay && filterDisplay.length > 0">
                        <div class="d-inline-flex" style="padding-bottom:4px;overflow-x:scroll">
                            <span class="mr-1">Filters: </span>
                            <div *ngFor="let singleFilter of filterDisplay | unique; let filterIndex = index;"
                                class="badge badge-secondary mx-1 d-flex">
                                {{singleFilter.column_name}}
                                <i class="fa fa-times ml-1 float-right cursor-pointer"
                                    (click)="removeFilter(dt,singleFilter)"></i>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </ng-template>
        <!--filter end-->
        <ng-template pTemplate="caption" *ngIf="bulddeleteflag">
            <button (click)="deletedIteam()">delete all </button>
        </ng-template>

        <!--table header start-->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 50px !important;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th class="srNo" style="width: 47px !important; padding-top: 10px !important">Sr.no.</th>
                <!--looping through column config to populate table header-->
                <ng-container *ngFor="let col of columnHeader">
                    <ng-container *ngIf="!col.hide">
                        <!--sortable column header-->
                        <th *ngIf="col.allowSort;else unsortable" [pSortableColumn]="col.field"
                            [ngStyle]="{'display': col.display,'width': col.width}">
                            {{col.header}}
                            <p-sortIcon>{{col.field}}</p-sortIcon>
                            <ng-container *ngIf="col.isFilter">
                                <ng-container *ngIf="col.dataType == 'date' || col.dataType == 'timestamp'">
                                    <p-columnFilter *ngIf="col.field != 'date_of_birth'" [showOperator]="false"
                                        [showAddButton]="true" [type]="col.dataType == 'string' ? 'text' : 'date'"
                                        [field]="col.field" display="menu" class="p-ml-auto">
                                        <ng-template pTemplate="filter" let-filterValue let-filter="filterCallback">
                                            <p-calendar [ngModel]="filterValue"
                                                [yearRange]="col.field != 'expire_date'?minFilterYear+':'+maxFilterYear:minFilterYear+':'+(maxFilterYear)"
                                                (onSelect)="filter($event)" [yearNavigator]="true" monthNavigator="true"
                                                dateFormat="dd/mm/yy"></p-calendar>
                                        </ng-template>
                                    </p-columnFilter>

                                </ng-container>
                                <ng-container *ngIf="col.dataType != 'date' && col.dataType != 'timestamp'">
                                    <p-columnFilter [yearNavigator]="true" [showOperator]="false"
                                        [showAddButton]="false"
                                        [type]="(col.dataType == 'string') ? 'text' : (col.dataType == 'numeric')?'numeric':'date'"
                                        [field]="col.field" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </ng-container>


                            </ng-container>
                        </th>
                        <!--header for unsortable columns-->
                        <ng-template #unsortable>

                            <th [ngStyle]="{'width': col.width}">{{col.header}}
                                <ng-container *ngIf="col.isFilter">
                                    <p-columnFilter
                                        [type]="(col.dataType == 'string') ? 'text' : (col.dataType == 'numeric')?'numeric':'date'"
                                        [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                                </ng-container>
                            </th>
                        </ng-template>
                    </ng-container>

                </ng-container>
            </tr>
        </ng-template>
        <!--table header end-->
        <!--table body start-->
        <ng-template pTemplate="body" class="dataContainer" let-editing="editing" let-rowData let-tableData
            let-ri="rowIndex">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">

                <td style="width: 50px !important;">
                    <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="srNo" style="width: 50px !important;">{{(pagenumercount*rowPerPage)+(ri+1)}}</td>
                <ng-container *ngFor="let col of columnHeader">
                    <td *ngIf="!col.hide" [ngStyle]="{'display': col.display,'width': col.width}"
                        [ngClass]="editTdStyle && editing? 'editTableRow' : ''">
                        <!--HTML for editable field start-->
                        <p-cellEditor *ngIf="col.allowEdit;else staticTemplate">
                            <!--HTML if edit is activated-->
                            <ng-template pTemplate="input">
                                <div class="profile-form">
                                    <mat-form-field appearance="outline" class="date-picker mr-2"
                                        style="width: 65% !important;"
                                        *ngIf="col.field != 'image' && col.dataType == 'date'">
                                        <input placeholder="DD-MM-YYYY" matInput [matDatepicker]="datepicker2"
                                            [min]="minDate" [max]="maxDate" [(ngModel)]="rowData[col.field]"
                                            placeholder="" autocomplete="off" />

                                        <mat-datepicker-toggle matSuffix [for]="datepicker2">
                                            <mat-icon svgIcon="calendar" style="width: 16px;" class="stroke-none-icon"
                                                matDatepickerToggleIcon></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #datepicker2>
                                            <mat-datepicker-actions>
                                                <button class="outlineBtn" mat-button
                                                    matDatepickerCancel>Cancel</button>
                                                <button mat-raised-button color="primary" matDatepickerApply>
                                                    Apply
                                                </button>
                                            </mat-datepicker-actions>
                                        </mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <input *ngIf="col.field != 'image' && col.dataType !== 'date'" type="text"
                                    [(ngModel)]="rowData[col.field]">
                                <input *ngIf="col.field == 'image'" type="file" [(ngModel)]="rowData[col.field]">
                            </ng-template>
                            <!--HTML if edit is deactivated-->
                            <ng-template pTemplate="output">
                                {{ (col.dataType == 'date' && rowData[col.field] != '0000-00-00 00:00:00' &&
                                rowData[col.field] != '' && rowData[col.field] != null)? (rowData[col.field] |
                                date:'yyyy-MM-dd'):rowData[col.field] }}
                            </ng-template>
                        </p-cellEditor>
                        <!--HTML for editable field end-->
                        <!--HTML for non editable field start-->
                        <ng-template #staticTemplate>
                            <span [ngStyle]="{'display': col.display}"
                                *ngIf="col.header != 'Ratings' && col.header != 'Review'">
                                <img *ngIf="col.field == 'image'" class="span_img mr-1 align-self-center"
                                    src="{{urlImages}}/{{rowData[col.field]}}">

                                {{ (col.dataType == 'date' && rowData[col.field] != '0000-00-00 00:00:00' &&
                                rowData[col.field] != '' && rowData[col.field] != null)? (rowData[col.field] |
                                date:'yyyy-MM-dd'):rowData[col.field] }}
                            </span>
                            <span *ngIf="col.header == 'Ratings'">
                                <!-- {{rowData[col.field]}} -->
                                <img class="rating_stars"
                                    src="../../../../assets/svg/Admin/feedback/ratings/img_{{rowData[col.field]}}.svg">
                            </span>
                            <span class="d-inline" [ngStyle]="{'max-width': col.width}"
                                style="text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;"
                                *ngIf="col.header == 'Review'">
                                {{rowData[col.field]}}

                            </span>
                        </ng-template>
                        <!--HTML for non editable field end-->
                        <!--For feedback table only-->
                        <ng-container class="d-flex" *ngIf="col.field=='feedback_actions'">

                            <!-- <button (click)="onViewClick(rowData)" *ngIf="col.field=='actions'"
                        class="btn btn-sm btn-default px-0 mr-1 text-nowrap">
                        <i class="fa fa-edit"></i>
                      </button> -->

                            <button (click)="onViewClick(rowData)"
                                class="btn btn-sm btn-default px-0 text-nowrap ml-1 mr-2">
                                <img class="profile_details"
                                    src="../../../../assets/svg/Admin/feedback/profile_detail.svg"
                                    alt="Profile Details">
                            </button>
                            <button (click)="showPopup(rowData)"
                                class="btn btn-sm btn-default tblBtn-small px-0 mr-1 text-nowrap">
                                <!-- <i class="fa fa-comments"></i> -->
                                <img class="feedback_chat" src="../../../../assets/svg/Admin/feedback/feedback_chat.svg"
                                    alt="Feedback Chat">
                            </button>
                        </ng-container>

                    </td>
                </ng-container>



            </tr>
            <!-- <tr *ngIf="rowData.length == 0">
            <td>No Records Found</td>
        </tr> -->
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" class="errorMsg">No Records Found.</td>
            </tr>
        </ng-template>
        <!---table body end-->
    </p-table>
</div>
<div *ngIf="bMobileView">
    <div class="">
        <h3 class="totalpages ml-2"><span class="mr-2">{{tableTitle}}</span>({{tableData.length}}
            of
            {{tableTotalCount}})
        </h3>
    </div>
    <div class="small-padding-bottom" *ngFor="let eachTableData of tableData">
        <mat-card>
            <mat-card-content>
                <div class="d-flex" style="flex-wrap: wrap;">

                    <div class="d-flex flex-half" *ngFor="let eachHeader of columnHeader;let type=even;">
                        <div class="d-flex flex-column" *ngIf="eachHeader.field != 'feedback_actions'">
                            <span class="label-font-size">{{eachHeader.header}}</span>
                            <span>{{eachTableData[eachHeader.field]}}</span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button (click)="onViewClick(eachTableData)" class="btn btn-sm btn-default px-0 text-nowrap ml-1 mr-2">
                    <img class="profile_details" src="../../../../assets/svg/Admin/feedback/profile_detail.svg"
                        alt="Profile Details">
                </button>
                <button (click)="showPopup(eachTableData)"
                    class="btn btn-sm btn-default tblBtn-small px-0 mr-1 text-nowrap">
                    <img class="feedback_chat" src="../../../../assets/svg/Admin/feedback/feedback_chat.svg"
                        alt="Feedback Chat">
                </button>
            </mat-card-actions>
        </mat-card>
    </div>

</div>

<p-paginator #paginator [rows]="10" [totalRecords]="tableTotalCount" [rowsPerPageOptions]="[10,20,50,100]"
    (onPageChange)="paginate($event)"></p-paginator>