import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {MatDrawer} from '@angular/material/sidenav/drawer';


@Injectable()
export class SidenavService {
    private sidenav: MatSidenav;
    private drawer: MatDrawer;

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public open() {
        return this.sidenav.open();
    }


    public close() {
        return this.sidenav.close();
    }

    public toggle(): void {
    this.sidenav.toggle();
   }

   setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
    }

    toggleDrawer() {
        this.drawer.close();
    }
}