import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appCapitalizeFirst]'
})
export class CapitalizeFirstDirective {
  @Input() capitalizeAfterSpaces : boolean;
  constructor(private ref: ElementRef) {
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    if (event.target.value.length === 1) {
      const inputValue = event.target.value;
      this.ref.nativeElement.value = inputValue.charAt(0).toUpperCase() + inputValue.substring(1);
      
    }
    if(this.capitalizeAfterSpaces) {        
      this.ref.nativeElement.value = this.titleCase(event.target.value)      
    }
  }

  titleCase(str) {
    let splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
 }


}