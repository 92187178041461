<div class="continer">
    <div style="display: flex;justify-content: space-between;">
        <h2 mat-dialog-title class="form-sub-heading letter-spacing">{{title}}</h2>
    </div>
    <hr>
    <div class="mt-12">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 mt-2">
                    <ng-select  [placeholder]="'Country'"
                        (change)="onCountrySelect($event)" appearance="outline" [clearOnBackspace]="true"
                        dropdownPosition="bottom" [(ngModel)]="country"
                        bindLabel="country_name">
                        <ng-option *ngFor="let country of countryList" [value]="country.country_name">
                            {{country.country_name}}</ng-option>
                    </ng-select>
                </div>

                <div class="col-md-6 mt-2 ">

                    <ng-select  [placeholder]="'State'" 
                        (change)="onStateSelect($event)" appearance="outline" [clearOnBackspace]="true"
                        dropdownPosition="bottom" [(ngModel)]="state" bindLabel="state_name">
                        <ng-option *ngFor="let state of stateList" [value]="state.state_name">{{state.state_name}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 mt-2 ">

                    <ng-select  [placeholder]="'City'" [(ngModel)]="city"  appearance="outline"
                        [clearOnBackspace]="true" dropdownPosition="bottom" [(ngModel)]="city"
                        bindLabel="city_name">
                        <ng-option *ngFor="let city of cityList" [value]="city.city_name">{{city.city_name}}</ng-option>
                    </ng-select>

                </div>
                <div class="col-md-6 mt-2 ">
                    <ng-select  [placeholder]="'Plan'" [(ngModel)]="plan"
                        appearance="outline" [clearOnBackspace]="true" dropdownPosition="bottom" bindLabel="item_text">
                        <ng-option *ngFor="let gender of dropdownList" [value]="gender.title">{{gender.title}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>



        </div>
    </div>
    <div class="mt-1">
        <div class="col-md-12 ">
            <button mat-flat-button class="theme-button1 float-right btnClass" style="padding:4px 25px !important" (click)="onConfirm()">Save</button>
            <button mat-flat-button class="theme-button float-right mr-4 btnClass"
             (click)="onDismiss()">Cancel</button>
        </div>
    </div>
</div>