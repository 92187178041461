import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpPipe'
})
export class HttpPipe implements PipeTransform {

  transform(href: string) {
    if(href){
      return href.startsWith("http://") || href.startsWith("https://") ? href : "http://" + href
    }
  }

}
