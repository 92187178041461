import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { AlphaonlyDirective } from './../directives/alphaonly.directive';
import { MaxNumberLengthDirective } from './../directives/max-number-length.directive';
import { DropdownDirective } from './../directives/dropdown.directive';
import { CapitalizeFirstDirective } from './../directives/capitalize-first.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from './../material/material.module';
import { SharedRoutingModule } from './shared-routing.module';
import { AlertSnackbarComponent } from './snackbar/alert-snackbar/alert-snackbar.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { WhyUpgradePlanComponent } from './components/why-upgrade-plan-popup/why-upgrade-plan.component';
import { DobPopupComponent } from './components/dob-popup/dob-popup.component';
import { SafePipe } from './pipes/safe.pipe';
import { CareerFitGraphComponent } from './components/career-fit-graph/career-fit-graph.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'primeng/chart';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HttpPipe } from './pipes/http-pipe.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { FeedbackPopupComponent } from './components/feedback/feedback-popup.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { UniquePipe } from './pipes/unique.pipe';
import { CalendarModule } from 'primeng/calendar';
import { DisclaimerPopupComponent } from './components/disclaimer/disclaimer-popup.component';
import { ReportStatusComponent } from '../assessments/components/report-status/report-status.component';
import { OridinalDatePipePipe } from './pipes/oridinal-date-pipe.pipe';
@NgModule({
  declarations: [
    CapitalizeFirstDirective,
    DropdownDirective,
    MaxNumberLengthDirective,
    AlertSnackbarComponent,
    ProgressCircleComponent,
    CustomPopupComponent,
    WhyUpgradePlanComponent,
    AlphaonlyDirective,
    DobPopupComponent,
    FeedbackPopupComponent,
    SafePipe,
    CareerFitGraphComponent,
    HttpPipe,
    FooterComponent,
    SimpleTableComponent,
    DisclaimerPopupComponent,
    ReportStatusComponent,
    UniquePipe,
    OridinalDatePipePipe
  ],
  imports: [
    SharedRoutingModule,
    CommonModule,
    MaterialModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatMomentDateModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    NgxChartsModule,
    ChartModule,
    PaginatorModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule,
    NgCircleProgressModule.forRoot({

    })
    /*mat*/
  ],
  exports: [
    CommonModule,
    MaterialModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatMomentDateModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    CapitalizeFirstDirective,
    DropdownDirective,
    MaxNumberLengthDirective,
    ProgressCircleComponent,
    AlphaonlyDirective,
    SafePipe,
    UniquePipe,
    OridinalDatePipePipe,
    CareerFitGraphComponent,
    HttpPipe,
    FooterComponent,
    SimpleTableComponent,
    DisclaimerPopupComponent,
    ReportStatusComponent
  ]
})
export class SharedModule { }
