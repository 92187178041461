import { environment } from 'src/environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { StudentService } from 'src/app/student/services/student.service';
@Injectable({ providedIn: 'root' })
export class FacebookService {
    identifierId = '';
    userDetails: any;
    mediaDetails: any;
    public customSubject = new Subject<any>();
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private zone:NgZone,
        private _snackBar:MatSnackBar,
        private _studentService: StudentService
    ) {
        
    }
    
    customObservable = this.customSubject.asObservable();

    getLoginURL() {
        let params = {
            client_id:environment.social_media.facebookAppId,
            //redirect_uri:'https://develop.d3bf9b4lgay1rh.amplifyapp.com/student/profile',
            redirect_uri:window.location.origin + '/shared/facebook',
            state:this.randomString(),
        }

        return `https://www.facebook.com/v10.0/dialog/oauth?client_id=${params.client_id}&redirect_uri=${params.redirect_uri}&state=${params.state}`
    }

    requestAccessToken(code) {
        let params = {
          client_id:environment.social_media.facebookAppId,
          client_secret:'e2076962d96953a8b9760327288513cf',
          code:code,
          grant_type:'authorization_code',
          redirect_uri:window.location.origin + '/shared/facebook',
        }
        return this.http.get(`${environment.ApiConfig.student}/student/social/facebook?client_id=${params.client_id}&client_secret=${params.client_secret}&code=${code}&redirect_uri=${params.redirect_uri}`)
      }

    randomString() {  
        //define a variable consisting alphabets in small and capital letter  
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz1234567890";  
            
          //specify the length for the new string  
      var lenString = 7;  
      var randomstring = '';  

          //loop to select a new character in each iteration  
      for (var i=0; i<lenString; i++) {  
      var rnum = Math.floor(Math.random() * characters.length);  
      randomstring += characters.substring(rnum, rnum+1);  
      }  
      return randomstring;
   }

    facebookUnlink() {
        
        // if(this.userDetails && this.userDetails.social_media){
        //     this.userDetails.social_media = JSON.parse(this.userDetails.social_media);
        // }
        if(this.userDetails.social_media && this.userDetails.social_media.length > 0){
            this.userDetails.social_media.forEach((element,index) => {
                if (element.social_media == "Facebook"){
                  this.userDetails.social_media.splice(index,1)
                }
            });
        }
        let mediaDetails = {
          social_media:this.userDetails.social_media
        }
        this._studentService.updateStudentDetails(mediaDetails, 'basic').subscribe(
            (data:any) => {
                if(data.success){
                    this.customSubject.next();//call basic tab get call
                    this._snackBar.open('You have successfully unlinked your social media account with ProTeen.', 'x', {
                        duration: 3 * 1000,
                        verticalPosition: 'bottom',
                        panelClass: ['success-snackbar']
                    });
                }else{
                    this._snackBar.open('Something went wrong.', 'x', {
                        duration: 3 * 1000,
                        verticalPosition: 'bottom',
                        panelClass: ['error-snackbar']
                    });
                }
            },
            err => {
                this._snackBar.open('Something went wrong.', 'x', {
                    duration: 3 * 1000,
                    verticalPosition: 'bottom',
                    panelClass: ['error-snackbar']
                });
                console.log(err);
            }
        );
            
        
    }

    showSnackError() {
        this.zone.run(() => {
            this._snackBar.open('Something went wrong.', 'x', {
                duration: 3 * 1000,
                verticalPosition: 'bottom',
                panelClass: ['error-snackbar']
            });
        })
        
    }
    
    updateBasic() {
        this.customSubject.next();
    }

    showSnackSuccess() {
        this.customSubject.next()
        this.zone.run(() => {
            this._snackBar.open('You have successfully linked your social media account with ProTeen.', 'x', {
                duration: 3 * 1000,
                verticalPosition: 'bottom',
                panelClass: ['success-snackbar']
            });
        })
        
    }
    

}