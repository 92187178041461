import { HostListener, ElementRef } from '@angular/core';
import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphaonly]'
})
export class AlphaonlyDirective {

  constructor(private ref: ElementRef,private control : NgControl) {
  }

  @HostListener('input', ['$event'])
  onInput(event:any) {
    //First regex accepts letters & spaces
    //second regex replaces multiple spaces with single space
    this.ref.nativeElement.value = event.target.value.replaceAll(/\s\s+/g, ' ').replaceAll(/[^A-Za-z ]*/g, '')
    if(this.control && this.control.control)  {
      this.control.control.patchValue(this.ref.nativeElement.value)
    }
  }

}
