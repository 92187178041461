<div class="container">
    <div class="row d-flex info-dialog justify-content-center" mat-dialog-content>
        <img [src]="data.imgPath" class="mb-1" style="height: 110px;">
        <div class="text-center form-sub-heading" [innerHTML]="data.msg">
        </div>
    </div>
    <div *ngIf="!alertMsgFlag">
        <div *ngIf="attempts" class="row d-flex info-dialog justify-content-center">
            <div *ngIf="attempts != 1"class="text-center font-weight-bold" style="color:red">You have {{attempts - 1}} attempts left.</div>
            <div *ngIf="attempts == 1"class="text-center font-weight-bold" style="color:red">This is your last attempt.</div>
        </div>
    </div>

    <div *ngIf="alertMsgFlag">
        <div class="row d-flex info-dialog justify-content-center">
            <!-- <div  style="color:red">You don't exist, go away!</div> -->
        </div>
    </div>

    <div class="row" mat-dialog-actions>
        <div *ngIf="!alertMsgFlag" class="col-sm-12 text-center pt-1 pb-2 d-flex submit-btn">
            <button mat-flat-button class="action-btn dialog-btn cancel-button shadowClass action-btn-text w-100"
                (click)="closeDialog()" translate="no">Cancel</button>
            <button mat-flat-button class="action-btn dialog-btn shadowClass action-btn-text w-100"
                (click)="onConfirm()" translate="no">Submit</button>
        </div>
        <div *ngIf="alertMsgFlag" class="col-sm-12 text-center pt-1 pb-2">
            <button mat-flat-button class="action-btn dialog-btn cancel-button shadowClass action-btn-text w-100"
                (click)="closeDialog()" translate="no">Ok</button>

        </div>
    </div>
</div>
