import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor(private cookieService:CookieService) { }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  }

  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  setCookie(key: string, value: string) {
    this.cookieService.set(key, value, { expires: 7, sameSite: 'Lax', path:'/' });
    //alert(document.cookie)
  }

  deleteAllCookies() {
    this.cookieService.deleteAll();
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!localStorage
  }
}
