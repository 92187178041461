import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryselectService {
  apiToken = '5Q0mYS9XIVbaNW1q8tdgX5p5ifBpR4Q75C31szPAhCIOythyBLVs1fGNI-4ouo7TUmg'
  authToken = ''
  //apiUrl = 'https://www.universal-tutorial.com/api';
  BASE_API_URL = environment.ApiConfig.settings;
  apiUrl = `${this.BASE_API_URL}/settings`
  public countryList = []
  httpOptions:any;
  initHeaders:any = {
    headers: new HttpHeaders({ 
      "Accept": "application/json",
    "api-token": this.apiToken,
    "user-email": "chanchal.chandanbatwe@softdel.com"
    })     
    
  };
  constructor(private http:HttpClient) { 
    this.httpOptions = {
      headers: new HttpHeaders({ 
        'Accept': 'application/json',
        //'Authorization': `Bearer ${data.auth_token}`,
      })     
    };
    this.getCountries().subscribe((data:any) => {
      this.countryList = data
      localStorage.countries = JSON.stringify(data)
    })
  }

  getCountries() {
    return this.http.get(`${this.apiUrl}/countries-states-cities`,this.httpOptions)
  }
  
  getStates(country) {
    return this.http.get(`${this.apiUrl}/countries-states-cities?country=${country}`,this.httpOptions)
  }

  getCities(state) {
    return this.http.get(`${this.apiUrl}/countries-states-cities?state=${state}`,this.httpOptions)
  }
  
}
