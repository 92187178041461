import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {
  apiUrl = '';
  BASE_API_URL = 'https://api.twitter.com/oauth'
  callbackURL = '';
  constructor(
    private http:HttpClient,
    private _snackBar:MatSnackBar,
  ) { 
    this.apiUrl = this.BASE_API_URL;
    this.callbackURL = window.location.origin + '/twitter';
  }

  getOAuthToken() {
    const httpOptions =  {
      headers: new HttpHeaders({
        'Authorization':`OAuth oauth_consumer_key="fFXOjTFtpG2Sb12U9QhPFrN3G",oauth_token="113865030-BJquviwjA3Bd4VZKkASxwoWHmCOfnTb8Hd7gQNz1",oauth_signature_method="HMAC-SHA1",oauth_timestamp="1618073777",oauth_nonce="zLbq11v0y6t",oauth_version="1.0",oauth_callback="http%3A%2F%2Flocalhost%3A4200%2Ftwitter",oauth_signature="dZ7Zt%2FrsdWPDtNwjrCamo4Up14c%3D" `
      })
      //oauth_nonce="ULJSM8Wt9VA",
      //oauth_timestamp="1618071347", 
      //oauth_signature="9EH%2FbvH9JQj56Eme3EIw3OqHUZs%3D"
      //CALL Backend API and fetch OAuth Token
    }
    return this.http.post(`${this.apiUrl}/request_token`,{},httpOptions)
  }

  getLoginURL() {
    return 'https://api.twitter.com/oauth/authenticate?oauth_token=';
  }
  
  getFinalAccessToken(token,verifier) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization':`OAuth oauth_consumer_key="${environment.social_media.twitter.consumer_key}",
        oauth_token="${token}",
        oauth_verified="${verifier}",
        oauth_signature_method="HMAC-SHA1",               
        oauth_version="1.0",
        oauth_callback="${this.callbackURL}",
        oauth_nonce="ULJSM8Wt9VA",
        oauth_timestamp="1618071347", 
        oauth_signature="9EH%2FbvH9JQj56Eme3EIw3OqHUZs%3D"`
      })
    }
    return this.http.post(`${this.apiUrl}/access_token`,{},)
  }

  showSnackError(errorMsg = 'Something went wrong') {
    this._snackBar.open(errorMsg, 'x', {
        duration: 3 * 1000,
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
   }

}
/*
oauth_consumer_key="${environment.social_media.twitter.consumer_key}",
        oauth_token="${environment.social_media.twitter.access_token}",
        oauth_signature_method="HMAC-SHA1",               
        oauth_version="1.0",
        oauth_callback="${this.callbackURL}",
        oauth_nonce="ULJSM8Wt9VA",
      oauth_timestamp="1618071347", 
      oauth_signature="9EH%2FbvH9JQj56Eme3EIw3OqHUZs%3D"`
      */