import { TwitterComponent } from './components/twitter/twitter.component';
import { InstagramComponent } from './components/instagram/instagram.component';
import { LinkedinComponent } from './components/linkedin/linkedin.component';
import { FacebookComponent } from './components/facebook/facebook.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../helpers/auth.guard';
import { FeedbackPopupComponent } from './components/feedback/feedback-popup.component';

const routes: Routes = [
  { 
    path: "facebook",
    component: FacebookComponent,
    canActivate:[AuthGuard] 
  },
  { 
    path: "linkedin",
    component: LinkedinComponent,
    canActivate:[AuthGuard] 
  },
  { 
    path: "instagram",
    component: InstagramComponent,
    canActivate:[AuthGuard] 
  },
  { 
    path: "twitter",
    component: TwitterComponent,
    canActivate:[AuthGuard] 
  },
  { 
    path: "feedback",
    component: FeedbackPopupComponent,
    canActivate:[AuthGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
