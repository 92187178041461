import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PsychometricService } from '../../app/assessments/services/psychometric.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'personality-tour-guide',
  templateUrl: './personality-tour-guide.component.html',
  styleUrls: ['./personality-tour-guide.component.scss']
})
export class PersonalityTourGuideComponent implements OnInit {
  subscription: Subscription;
  @ViewChild('cursori') cursoricon: ElementRef;
  showSkipCloseBtn = false;
  questions = [{ title: 'I am not inclined to forgive others.', answer: 'null' },
  { title: 'I neglect my duties and take tasks too lightly.', answer: 'null' },
  { title: 'I spend a lot of time reflecting on things.', answer: 'null' },
  { title: 'I am not inclined to forgive others.', answer: 'null' },
  { title: 'I neglect my duties and take tasks too lightly.', answer: 'null' },
  { title: 'I spend a lot of time reflecting on things.', answer: 'null' },
  ]
  imgArr = [{ src: '../../assets/svg/assessments/tour/personality/s4.png', src1: '../../assets/svg/assessments/tour/personality/s4-s.png', padding: '5px' },
  { src: '../../assets/svg/assessments/tour/personality/s2.png', src1: '../../assets/svg/assessments/tour/personality/s2-s.png', padding: '15px' },
  { src: '../../assets/svg/assessments/tour/personality/s3.png', src1: '../../assets/svg/assessments/tour/personality/s3-s.png', padding: '22px' },
  { src: '../../assets/svg/assessments/tour/personality/s5.png', src1: '../../assets/svg/assessments/tour/personality/s5-s.png', padding: '15px' },
  { src: '../../assets/svg/assessments/tour/personality/s1.png', src1: '../../assets/svg/assessments/tour/personality/s1-s.png', padding: '5px' }]

  currentIndex = 0; // for click question focus
  scrollIndex = 0; // for back question focus
  selectedQuestion = 0; // for next index focus
  constructor(public _psychometricService: PsychometricService,) {
    this.subscription = this._psychometricService.SharingAnimationDataPersonality.subscribe((res: any) => {
      this._psychometricService.showOverlayPersonality = res.showOverlay;
      this.startTour();
    })
  }

  ngOnInit(): void {
  }

  closeTour() {
    this._psychometricService.showOverlayPersonality = false;
    this.currentIndex = 0;
    this.scrollIndex = 0;
    this.selectedQuestion = 0;
    var value1 = {
      startTimer: true
    }
    this._psychometricService.startTimerDataPersonality.next(value1);
    this.questions = [{ title: 'I am not inclined to forgive others.', answer: 'null' },
    { title: 'I neglect my duties and take tasks too lightly.', answer: 'null' },
    { title: 'I spend a lot of time reflecting on things.', answer: 'null' },
    { title: 'I am not inclined to forgive others.', answer: 'null' },
    { title: 'I neglect my duties and take tasks too lightly.', answer: 'null' },
    { title: 'I spend a lot of time reflecting on things.', answer: 'null' },
    ]
  }

  startTour() {
    setTimeout(() => {
      if (this.cursoricon && this.cursoricon != undefined) {
        this.cursoricon.nativeElement.className = 'firstTrans';
      }
      setTimeout(() => {
        if (this.cursoricon && this.cursoricon != undefined) {
          this.cursoricon.nativeElement.style.animation = "ripple-effect 1s ease";
          this.questions[0].answer = '0'
          this.scrollIndex = 1; //current index used for scroll on back n submit btn
          this.currentIndex = 0; //this index used for focus on current select question
          this.selectedQuestion = 0; //This index used for navigate to next question
          if (this.questions.length - 1 != this.scrollIndex) {
            this.selectedQuestion++; //When select question answer then that should be navigate to next question that's why index++
          }
          this.radioChange(1);
        }
      }, 800);
      //Second transition
      setTimeout(() => {
        if (this.cursoricon && this.cursoricon != undefined) {
          this.cursoricon.nativeElement.className = 'secondTrans';
        }
        setTimeout(() => {
          if (this.cursoricon && this.cursoricon != undefined) {
            this.cursoricon.nativeElement.style.animation = "ripple-effect1 1s ease";
            this.questions[1].answer = '1'
            this.scrollIndex = 2; //current index used for scroll on back n submit btn
            this.currentIndex = 1; //this index used for focus on current select question
            this.selectedQuestion = 1; //This index used for navigate to next question
            if (this.questions.length - 1 != this.scrollIndex) {
              this.selectedQuestion++; //When select question answer then that should be navigate to next question that's why index++
            }
            this.radioChange(2);
          }
        }, 800);
        //Third transition
        setTimeout(() => {
          if (this.cursoricon && this.cursoricon != undefined) {
            this.cursoricon.nativeElement.className = 'thirdTrans';
          }
          setTimeout(() => {
            if (this.cursoricon && this.cursoricon != undefined) {
              this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
              this.questions[2].answer = '2'
              this.scrollIndex = 3; //current index used for scroll on back n submit btn
              this.currentIndex = 2; //this index used for focus on current select question
              this.selectedQuestion = 2; //This index used for navigate to next question
              if (this.questions.length - 1 != this.scrollIndex) {
                this.selectedQuestion++; //When select question answer then that should be navigate to next question that's why index++
              }
              this.radioChange(3);
            }
          }, 800);
          //Fourth transition
          setTimeout(() => {
            if (this.cursoricon && this.cursoricon != undefined) {
              this.cursoricon.nativeElement.className = 'fourthTrans';
            }
            setTimeout(() => {
              if (this.cursoricon && this.cursoricon != undefined) {
                this.cursoricon.nativeElement.style.animation = "ripple-effect3 1s ease";
                this.questions[3].answer = '3'
                this.scrollIndex = 4; //current index used for scroll on back n submit btn
                this.currentIndex = 3; //this index used for focus on current select question
                this.selectedQuestion = 3; //This index used for navigate to next question
                if (this.questions.length - 1 != this.scrollIndex) {
                  this.selectedQuestion++; //When select question answer then that should be navigate to next question that's why index++
                }
                this.radioChange(4);
              }
            }, 800);
            //Fifth transition
            setTimeout(() => {
              if (this.cursoricon && this.cursoricon != undefined) {
                this.cursoricon.nativeElement.className = 'fifthTrans';
              }
              setTimeout(() => {
                if (this.cursoricon && this.cursoricon != undefined) {
                  this.cursoricon.nativeElement.style.animation = "ripple-new 1s ease";
                  this.questions[4].answer = '4'
                  this.scrollIndex = 5; //current index used for scroll on back n submit btn
                  this.currentIndex = 4; //this index used for focus on current select question
                  this.selectedQuestion = 5; //This index used for navigate to next question
                  this.radioChange(5);
                }
              }, 800);
            }, 2500);
          }, 2500);
        }, 2500);
      }, 2500);
    }, 2500);
  }

  //Radio changes function
  radioChange(index) {
    setTimeout(() => { //current question focus should get remove after some time
      this.currentIndex = -1;
    }, 800);
    setTimeout(() => {
      var e = document.getElementById(`element_div${index}`);
      if (e && e != undefined) {
        e.scrollIntoView({ behavior: 'smooth' })
      }
    }, 800);

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
