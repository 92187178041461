import { AuthService } from 'src/app/services/auth.service';
import { CountryselectService } from 'src/app/shared/services/countryselect.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-social-login',
  templateUrl: './auto-social-login.component.html',
  styleUrls: ['./auto-social-login.component.scss']
})
export class AutoSocialLoginComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,
              private _authService:AuthService,
              private _countryService:CountryselectService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if(params.provider == 'Google') {
        this.signInWithGoogle();
      }
      else {
        this.signInWithFacebook();
      }
    })
  }

  async signInWithFacebook() {
    this.fetchCountryStates();
    const socialResult = await this._authService.socialSignIn(AuthService.FACEBOOK);
    //console.log('fb Result:', socialResult);
  }

  async signInWithGoogle() {
    this.fetchCountryStates();
    const socialResult = await this._authService.socialSignIn(AuthService.GOOGLE);
    //console.log('google Result:', socialResult);
  }

  fetchCountryStates() {
    this._countryService.getCountries().subscribe(data => {
      localStorage.countries = JSON.stringify(data);
    });
    this._countryService.getStates('India').subscribe(data => {
      localStorage.states = JSON.stringify(data)
    });
  }

}
