import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from './../../pricing/services/payment.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { PlanPopupComponent } from 'src/app/pricing/components/plan-popup/plan-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AttemptsPopupComponent } from 'src/app/assessments/components/popup/attempts-popup/attempts-popup.component';

@Component({
  selector: 'app-aicte',
  templateUrl: './aicte.component.html',
  styleUrls: ['./aicte.component.scss']
})
export class AicteComponent implements OnInit {
  studentData:any = {}
  paymentInProgress = false;
  constructor(private router:Router,
    private spinner:NgxSpinnerService,
    private _paymentService:PaymentService,
    private winRef: WindowRefService,
    private dialog:MatDialog,
    private zone:NgZone,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.sendParamData(params)
    })
  }

  sendParamData(params) {
    console.log(params);
    this.createRzpayOrder(params)
  }

  createRzpayOrder(data) {
    console.log(data);
    this.paymentInProgress = true;
    // call api to create order_id
    let postData:any = {
      //uuid:sessionStorage.getItem('userSub'),
      plan:'Essential',
      affiliated_by:'NEAT',
      course_id:data.courseid,
      course_name:data.coursename,
      session_id:data.sessionid,
      student_name:data.studentname,
      student_id:data.studentid,
      email:data.email,
      fee:data.fee,
      username:data.mobile,
      phone_number:data.mobile,
      domain:environment.domain,
      entity_id:4,
    }
    this.studentData = JSON.parse(JSON.stringify(postData))
    this._paymentService.getNeatOrderId(postData).subscribe((orderData:any) => {
      console.log(orderData)
      this.payWithRazor(orderData);
    }, error => {console.log(error); this.paymentInProgress = false;})

    
    //this.payWithRazor('test');
  }

  payWithRazor(val) {
    let options: any = {
      key: environment.razorPayKey,//'rzp_test_FjzDoZGX9ZzVPB',//'rzp_test_impe1c9O95Dmqj',
      //amount: 500,//this.selectedPlan.cost*100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'ProTeen', // company name or product name
      description: '',  // product description
      image: '../../../../assets/svg/Proteen-Logo.svg', // company logo or product image
      order_id: val.id, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#FF6127'
      }
    };
    /*if(!environment.production) {
      delete options.order_id
    }*/
    this.spinner.show();
  
    options.handler = ((response, error) => {
      options.response = response;
      this._paymentService.fetchNeatPaymentDetails({payment_id:response.razorpay_payment_id, uuid:val.sub,order_id:val.id}).subscribe((backendResponse:any) => {
        console.log(backendResponse);
        if(backendResponse.success) {
          this.zone.run(() => {
            let redirectUrl = `http://neat.aicte-india.org/NEATstudentresponse.php?studentid=${this.studentData.student_id}&sessionid=${this.studentData.session_id}&payment=1&courseid=${this.studentData.course_id}&Success=1`
            //let dialogRef = this.dialog.open(PlanPopupComponent, {data: {msg: 'Payment has been successfully completed.',alertMsgFlag:true,title:'Success'}});
            this.paymentInProgress = false;
            this.spinner.hide();
            window.location.href = redirectUrl;
            // dialogRef.afterClosed().subscribe(() => {
              
            // })
          });
            
          
          
        }
        else {
          this.zone.run(() => {
            this.paymentInProgress = false;
            let redirectUrl = `http://neat.aicte-india.org/NEATstudentresponse.php?studentid=${this.studentData.student_id}&sessionid=${this.studentData.session_id}&payment=1&courseid=${this.studentData.course_id}&Failure=1`
            //let dialogRef = this.dialog.open(AttemptsPopupComponent, {data: {msg: 'Payment has failed.',alertMsgFlag:true,title:'Error'}});
            this.spinner.hide();
            window.location.href = redirectUrl;
          });
        }
      })
      console.log('res',response);
      console.log('option',options);
      
  
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = ((data) => {
      // handle the case when user closes the form while transaction is in progress
      this.paymentInProgress = false;
      console.log('Transaction cancelled.',data);    
      this.spinner.hide();
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
    this.spinner.hide();
  
  }

}
