import { AuthService } from 'src/app/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };
  private apiUrl:string;  
  private userSub;

  constructor(private _authService:AuthService,private http:HttpClient) {
    const BASE_API_URL = environment.ApiConfig.student;
    this.apiUrl = `${BASE_API_URL}`;
    this.userSub = sessionStorage.getItem('userSub')
    this._authService.getUserDetails().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Authorization': data.user.signInUserSession.idToken.jwtToken,
        })        
      };
      this.userSub = data.user.attributes.sub;
      //this.http.options(this.httpOptions);
      
      
    })
  }

  getOrderId(postData) {
    return this.http.post(`${this.apiUrl}/student/payments/orders`,postData,this.httpOptions); 
  }

  getNeatOrderId(postData) {
    return this.http.post(`${this.apiUrl}/student/payments/others/orders`,postData,this.httpOptions); 
  }

  getCouponDetails(couponCode) {
    return this.http.get(`${this.apiUrl}/student/payments/coupon?coupon_code=${couponCode}&entity_id=${environment.entity_id}`,this.httpOptions);
  }

  fetchPaymentDetails(data) {
    return this.http.post(`${this.apiUrl}/student/payments/fetch`,data,this.httpOptions); 
  }

  fetchNeatPaymentDetails(data) {
    return this.http.post(`${this.apiUrl}/student/payments/others/fetch`,data,this.httpOptions); 
  }
}
