import { StudentService } from 'src/app/student/services/student.service';
import { MessageService } from 'src/app/assessments/services/message.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { CountryselectService } from 'src/app/shared/services/countryselect.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptionService } from './../../services/encryption.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.scss']
})
export class AutologinComponent implements OnInit {
  username: string = '';
  password: string = '';
  constructor(
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private _messageService:MessageService,
    private _countryService:CountryselectService,
    private _localStorage:LocalstorageService,
    private _authService: AuthService,
    private _studentService:StudentService,
    private _router:Router,
    private _encryptionService: EncryptionService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      let username = params.u;
      let pass = params.p;
      this.username = this._encryptionService.decrypt(decodeURIComponent(username));
      this.password = this._encryptionService.decrypt(decodeURIComponent(pass));
      //Force login
      this._authService.signIn(this.username, this.password)
        .then(data => {
          this._authService.getCurrentUserObj().then(userData => {
            let token = userData.signInUserSession.idToken.jwtToken;
            this._localStorage.setCookie('token', token);
            localStorage.setItem('token', token);
            //console.log('data.attributes["custom:role"] ', data.attributes["custom:role"]);
            if (data.attributes["custom:role"] === 'Parent') {
              this._router.navigate(['/parent/dashboard']);
              this.spinner.hide();

            }
            else {
              this._studentService.getStudentDetails('basic').subscribe((basicData) => {
                //console.log(basicData[0])
                this._localStorage.set(`${data.attributes.sub}_basic`, basicData[0]);
                if (localStorage.getItem('returnUrl')) {
                  let returnUrl = localStorage.getItem('returnUrl');
                  localStorage.removeItem('returnUrl');
                  this._router.navigateByUrl(returnUrl);
                }
                else {
                  
                  this._router.navigate(['/student/dashboard']);
                }
              }, err => console.log(err));

              this.spinner.hide();

            }
          })
          .catch((err) => console.log(err));
          sessionStorage.setItem('userSub', data.attributes.sub);
          
          //FETCH Indian states and country list
          this._countryService.getCountries().subscribe(data => {
            localStorage.countries = JSON.stringify(data);
          });
          this._countryService.getStates('India').subscribe(data => {
            localStorage.states = JSON.stringify(data);
          });
          
          

        }).catch((err) => console.log(err));
    });
  }

}
