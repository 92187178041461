import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';

//check if env is local and update redirect urls for social signin
if(window.location.hostname != 'develop.d3bf9b4lgay1rh.amplifyapp.com' && window.location.hostname != 'staging.d3bf9b4lgay1rh.amplifyapp.com' && window.location.hostname != 'master.d3bf9b4lgay1rh.amplifyapp.com') {
  environment.AWSConfig.oauth.redirectSignIn = window.location.origin + "/proteen/select-role";
  environment.AWSConfig.oauth.redirectSignOut = window.location.origin + "/proteen/login";
}

Auth.configure(environment.AWSConfig);
Storage.configure(environment.AWSConfig);

if (environment.production) {
  enableProdMode();
  //environment.apiType = 'prod';
  //Disable console messages on prod
  if(window.console) {
    console.log = function() {};
    console.warn = function() {};
  }
}
else {
  //  if(environment.env == 'preprod') {
  //    if(window.console) {
  //      console.log = function() {};
  //      console.warn = function() {};
  //    }
  //  }
 //environment.ApiConfig.adminUrl = 
}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
