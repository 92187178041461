import { StudentService } from './../../student/services/student.service';
import { environment } from './../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  apiUrl = 'https://api.instagram.com/oauth'
  redirectURL = window.location.origin + '/shared/instagram';
  userDetails: any;
  mediaDetails: any;
  public customSubject = new Subject<any>();
  constructor(private http:HttpClient,
    private _snackBar:MatSnackBar,
    private _studentService:StudentService,
    private zone:NgZone) { 
    
   
  }
  customObservable = this.customSubject.asObservable();

  getLoginURL() {
    return `${this.apiUrl}/authorize?client_id=${environment.social_media.instagram.app_id}&redirect_uri=${this.redirectURL}&scope=user_profile&response_type=code&state=1`
  }

  showSnackSuccess() {
    this.customSubject.next()
    this.zone.run(() => {
        this._snackBar.open('You have successfully linked your social media account with ProTeen.', 'x', {
            duration: 3 * 1000,
            verticalPosition: 'bottom',
            panelClass: ['success-snackbar']
        });
    })
  }

  instagramUnlink() {
        
    // if(this.userDetails && this.userDetails.social_media){
    //     this.userDetails.social_media = JSON.parse(this.userDetails.social_media);
    // }
    console.log(this.userDetails)
    if(this.userDetails.social_media && this.userDetails.social_media.length > 0){
        this.userDetails.social_media.forEach((element,index) => {
            if (element.social_media == "Instagram"){
              this.userDetails.social_media.splice(index,1)
            }
        });
    }
    let mediaDetails = {
      social_media:this.userDetails.social_media
    }
    this._studentService.updateStudentDetails(mediaDetails, 'basic').subscribe(
        (data:any) => {
            if(data.success){
                this.customSubject.next();//call basic tab get call
                this._snackBar.open('You have successfully unlinked your social media account with ProTeen.', 'x', {
                    duration: 3 * 1000,
                    verticalPosition: 'bottom',
                    panelClass: ['success-snackbar']
                });
            }else{
                this._snackBar.open('Something went wrong.', 'x', {
                    duration: 3 * 1000,
                    verticalPosition: 'bottom',
                    panelClass: ['error-snackbar']
                });
            }
        },
        err => {
            this._snackBar.open('Something went wrong.', 'x', {
                duration: 3 * 1000,
                verticalPosition: 'bottom',
                panelClass: ['error-snackbar']
            });
            console.log(err);
        }
    );
        
    
}

  requestAccessToken(code) {
    let params = {
      client_id:environment.social_media.instagram.app_id,
      client_secret:'9ba10f6387333d0ef7dba3da6d0f12a1',
      code:code,
      grant_type:'authorization_code',
      redirect_uri:this.redirectURL
    }
    return this.http.get(`${environment.ApiConfig.student}/student/social/instagram?client_id=${params.client_id}&client_secret=${params.client_secret}&code=${code}&redirect_uri=${params.redirect_uri}`)
  }

  showSnackError(errorMsg = 'Something went wrong') {
    this._snackBar.open(errorMsg, 'x', {
        duration: 3 * 1000,
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
   }
}
