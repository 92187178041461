<div class="container">
    <div class="row d-flex info-dialog justify-content-center" mat-dialog-content>
        <div class="text-center form-sub-heading" [innerHTML]="data.msg">
        </div>
    </div>
    <div class="row" mat-dialog-actions>
        <div class="col-sm-12 text-center pt-1 pb-2">
            <button mat-flat-button class="action-btn dialog-btn cancel-button shadowClass action-btn-text w-100"
                (click)="closeDialog()">Cancel</button>
            <button mat-flat-button class="action-btn dialog-btn shadowClass action-btn-text w-100"
                (click)="onConfirm()">View Profile</button>
        </div>
    </div>
</div>