import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import * as confetti from 'canvas-confetti';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-final-popup',
  templateUrl: './final-popup.component.html',
  styleUrls: ['./final-popup.component.scss']
})
export class FinalPopupComponent implements OnInit {
  public isSmallDevice: boolean = false;
  public isLargeDevice: boolean = false;
  value = 200;
  left:any = false;
  public clicked = true;
  msg:any = false;
  constructor(public breakpointObserver: BreakpointObserver,private _router: Router, private renderer2: Renderer2,
    private elementRef: ElementRef,public dialog: MatDialogRef<FinalPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data && data.points)
      this.value = data.points;
    if(data && data.msg)
      this.msg = data.msg;
    if(data && data.left)
      this.left = data.left;
   
   }

  
  


  ngOnInit(): void {

    const canvas = this.renderer2.createElement('canvas');

    this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

    const myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true

    });

    myConfetti({
      particleCount: 800,
  spread: 100,
  origin: { y: 0.6 }
      // any other options from the global
      // confetti function
    });

    this.clicked = true;

    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isLargeDevice = true;
        this.isSmallDevice = false;
      }
    });




}

goToAssessment(){
  this._router.navigate(['/assessment']);
  this.dialog.close();
}

onDismiss(): void {
  this.dialog.close();
}
}