<div class="d-flex graph-container" *ngIf="careerFitData && careerFitData[careerClusterTitle]">
    <div class="col-12 col-md-3 d-flex flex-column rBrd justify-content-center align-items-center">
        <circle-progress [percent]="careerFitData[careerClusterTitle]['Overall'].percent" [radius]="27"
            [responsive]="true" [outerStrokeWidth]="5"
            [outerStrokeColor]="returnClrCode(careerFitData[careerClusterTitle]['Overall'].category)"
            [showInnerStroke]="true" [space]="-5" [innerStrokeColor]="'#e7e8ea'" [innerStrokeWidth]="5"
            [animation]="false" [showImage]="false" [showSubtitle]="false" [titleFontSize]="17" [unitsFontSize]="12"
            [titleColor]="'#808080'" [unitsColor]="'#808080'" [outerStrokeLinecap]="'square'"
            [toFixed]="0"></circle-progress>
        <div class="position-relative">
            <img *ngIf="showAsterisk" class="overallStar" src="../../../../assets/svg/FullReport/fitstar.svg" />
            <h3 class="textClr font-weight-bold font-18 text-center">{{headingName}}</h3>
        </div>
    </div>
    <div class="col-12 col-md-9 col-sm-12 pr-0 d-flex flex-column justify-content-center align-items-center">
        <div class="col-md-12 pl-0 pr-0 col-sm-12 d-flex pt-3 justify-content-start flex-column">
            <div
                class="custom-bar-realistic d-flex pr-0 pt-1 pb-2 {{returnProgressClrCode(careerFitData[careerClusterTitle]['Interest'].category)}}">
                <div style="width: 14%;" class="">
                    <span class="chart-label font-weight-bold graphTitle" translate="no">Interest</span>
                </div>
                <div style="width: 86%;"
                    class="d-flex justify-content-end align-items-center position-relative bar-graph">
                    <mat-icon class="graph_icon"
                        style="position:absolute;height:30px;width:5.5%;top:-16px;z-index:20;margin-left:2.8%"
                        [style.left]="(careerFitData[careerClusterTitle]['Interest'].percent)*0.94 +'%'"
                        svgIcon="graph_loc" [ngClass]="{
                        'icon-high': ((careerFitData[careerClusterTitle]['Interest'].category) == 'H'),
                        'icon-moderate': ((careerFitData[careerClusterTitle]['Interest'].category) == 'M'),
                        'icon-low': ((careerFitData[careerClusterTitle]['Interest'].category) == 'L')
                        }"></mat-icon>
                    <mat-progress-bar class="custom-ProgressBar " mode="determinate"
                        [value]="careerFitData[careerClusterTitle]['Interest'].percent">
                    </mat-progress-bar>
                </div>
            </div>
            <div
                class="custom-bar-realistic d-flex pr-0 pt-1 pb-2 mt-3 {{returnProgressClrCode(careerFitData[careerClusterTitle]['MI'].category)}}">
                <div style="width: 14%;" class="">
                    <span class="chart-label font-weight-bold graphTitle" translate="no">MI</span>
                </div>
                <div style="width: 86%;"
                    class="d-flex justify-content-end align-items-center position-relative bar-graph">

                    <mat-icon class="graph_icon"
                        style="position:absolute;height:30px;width:5.5%;top:-16px;z-index:20;margin-left:2.8%"
                        [style.left]="(careerFitData[careerClusterTitle]['MI'].percent)*0.94 +'%'" svgIcon="graph_loc"
                        [ngClass]="{
                    'icon-high': ((careerFitData[careerClusterTitle]['MI'].category) == 'H'),
                    'icon-moderate': ((careerFitData[careerClusterTitle]['MI'].category) == 'M'),
                    'icon-low': ((careerFitData[careerClusterTitle]['MI'].category) == 'L')
                    }"></mat-icon>
                    <mat-progress-bar class="custom-ProgressBar " mode="determinate"
                        [value]="careerFitData[careerClusterTitle]['MI'].percent">
                    </mat-progress-bar>
                </div>
            </div>
            <div class="custom-bar-realistic d-flex pr-0 pt-1 pb-2 mt-3 {{returnProgressClrCode(careerFitData[careerClusterTitle]['Personality'].category)}}"
                *ngIf="!streamFit">
                <div style="width: 14%;" class="">
                    <span class="chart-label font-weight-bold graphTitle" translate="no">Personality</span>
                </div>
                <div style="width: 86%;"
                    class="d-flex justify-content-end align-items-center position-relative bar-graph">
                    <mat-icon class="graph_icon"
                        style="position:absolute;height:30px;width:5.5%;top:-16px;z-index:20;margin-left:2.8%"
                        [style.left]="(careerFitData[careerClusterTitle]['Personality'].percent)*0.94 +'%'"
                        svgIcon="graph_loc" [ngClass]="{
                    'icon-high': ((careerFitData[careerClusterTitle]['Personality'].category) == 'H'),
                    'icon-moderate': ((careerFitData[careerClusterTitle]['Personality'].category) == 'M'),
                    'icon-low': ((careerFitData[careerClusterTitle]['Personality'].category) == 'L')
                    }"></mat-icon>
                    <mat-progress-bar class="custom-ProgressBar " mode="determinate"
                        [value]="careerFitData[careerClusterTitle]['Personality'].percent">
                    </mat-progress-bar>
                </div>
            </div>
            <div
                class="custom-bar-realistic d-flex pr-0 pt-1 pb-2 mt-3 {{returnProgressClrCode(careerFitData[careerClusterTitle]['Aptitude'].category)}}">
                <div style="width: 14%;" class="">
                    <span class="chart-label font-weight-bold graphTitle" translate="no">Aptitude</span>
                </div>
                <div style="width: 86%;"
                    class="d-flex justify-content-end align-items-center position-relative bar-graph">
                    <mat-icon class="graph_icon"
                        style="position:absolute;height:30px;width:5.5%;top:-16px;z-index:20;margin-left:2.8%"
                        [style.left]="(careerFitData[careerClusterTitle]['Aptitude'].percent)*0.94 +'%'"
                        svgIcon="graph_loc" [ngClass]="{
                    'icon-high': ((careerFitData[careerClusterTitle]['Aptitude'].category) == 'H'),
                    'icon-moderate': ((careerFitData[careerClusterTitle]['Aptitude'].category) == 'M'),
                    'icon-low': ((careerFitData[careerClusterTitle]['Aptitude'].category) == 'L')
                    }"></mat-icon>
                    <mat-progress-bar class="custom-ProgressBar " mode="determinate"
                        [value]="careerFitData[careerClusterTitle]['Aptitude'].percent">
                    </mat-progress-bar>
                </div>
            </div>
            <div class="custom-bar d-flex pr-0 pb-2 scale-bar">
                <div class="m-hide" style="width: 14%;">
                </div>
                <div style="width: 86%;" class="d-flex justify-content-end align-items-center img-container">
                    <div style="width: 5%;" class="m-hide">
                    </div>
                    <img class="d-img" style="width: 95%; padding-top: 27px;"
                        src="../../../../assets/svg/Careers/scale1.svg" />
                    <img class="m-img" style="width: 95%; padding-top: 27px;"
                        src="../../../../assets/svg/Careers/scale-mob.svg" />
                </div>
            </div>
        </div>
    </div>
</div>