<div *ngIf="isLargeDevice">
    <!-- <div class="float-right" mat-dialog-close><img src="../../../assets/svg/close.svg"/></div> -->
    <div class="row">
        <div class="cursor-pointer" style="margin-left: 465px;margin-top: -21px;z-index: 500px !important;">
            <img style="height: 15px;width: 15px;" class="close_popup" src="../../../assets/svg/close.svg" (click)="onDismiss()"/>

        </div>
        <img class="batchimagetop sm-topStar" src="../../../assets/svg/Icon weather-stars.svg"/>

        <div class="col-md-12 centeredImg">
            <img class="batchimage" src="../../../assets/svg/Earn_Points.svg"/>
            <!-- <span class="pointsText text-center">Earn</span><br> -->
            <span class="points text-center" [style.left]="left?left:null" translate="no">{{value}}</span><br>
            <span class="poin text-center" translate="no">Points</span> 
        </div>
        <img class="batchimagestar sm-bottomStar" src="../../../assets/svg/Icon weather-stars.svg"/>

    </div>
    <div class="row" style="margin-top: -15px !important;">
        <div class="col-md-12 text-center boldText pt-4" translate="no">
            Congratulations!
        </div>
        <div class="col-md-12 text-center boldText-below pt-4" translate="no">
            You have earned {{value}} points
        </div>

        <div *ngIf="!msg" class="col-md-12 text-center boldText-below pt-3 mt-3" style="margin-top: -10px !important;" translate="no">
            Now, complete your assessments to earn more points
        </div>
        <div *ngIf="!msg" class="col-md-12 mt-3" style="display: flex;justify-content: center;margin-bottom: -10px !important;" >
            <button mat-flat-button class="action-btn action-btn-text " (click)="goToAssessment()" translate="no">
              Go To Assessment
            </button>
          </div>
    </div>
</div>

<div *ngIf="isSmallDevice" class="notouchAction" style="margin-top: 16px;">
    <div class="cursor-pointer" (click)="onDismiss()"  style="margin-top: -9px;display: flex;margin-right: 7px!important;justify-content: flex-end;">
        <img style="height: 16px;width: 16px; z-index: 500;"   src="../../../assets/svg/close.svg" />

    </div>
    <!-- <div class="float-right" mat-dialog-close><img src="../../../assets/svg/close.svg"/></div> -->
    <div class="row">
        <img class="mbatchimagetop" src="../../../assets/svg/Icon weather-stars.svg"/>
        <!-- <img style="height: 15px;width: 15px;" class="close_popup" src="../../../assets/svg/close.svg" (click)="onDismiss()"/> -->

        <div class="col-md-12 centeredImg">
            <img class="batchimage" src="../../../assets/svg/Earn_Points.svg"/>
            <!-- <span class="pointsText text-center">Earn</span><br> -->
            <span class="points text-center" translate="no">{{value}}</span><br>
            <span class="poin text-center" translate="no">Points</span> 
        </div>
        <img class="batchimagestar" src="../../../assets/svg/Icon weather-stars.svg"/>

    </div>
    <div class="row">
        <div class="col-md-12 text-center boldText pt-2" translate="no">
            Congratulations!
        </div>
        <div class="col-md-12 text-center boldText-below pt-4" translate="no">
            You have earned {{value}} points
        </div>

        <div *ngIf="!msg" class="col-md-12 text-center boldText-below pt-3 mt-1" translate="no">
            Now, complete your assessments to earn more points
        </div>
        <div *ngIf="!msg" class="col-md-12 " style="display: flex;justify-content: center; margin-top: 12px;" >
            <button mat-flat-button class="action-btn action-btn-text " (click)="goToAssessment()" translate="no">
            Go To Assessment
            </button>
          </div>
    </div>
</div>