<div class="progress-indicator {{progressValue > 50?'over50':''}} p{{progressValue}} progressShadow">
    <span>
        <button class="btn btn-lg btn-circle">
            <mat-icon [svgIcon]="matIcon" class="mat-icon-progress"></mat-icon>
        </button>          
    </span>
    <div class="left-half-clipper">
      <div class="first50-bar"></div>
      <div class="value-bar"></div>
    </div>
    <span class="small-progress-indicator">
        <ng-container *ngIf="progressValue < 100">
            <div class="percentNumber d-flex" translate="no">{{progressValue}}<div class="percentSign"><sub translate="no">%</sub></div></div> 
        </ng-container>
        <ng-container *ngIf="progressValue == 100">
            <div class="material-icons right-icon" translate="no">
                    done
            </div>
       </ng-container>
        
    </span>
    
</div>