
<div class="container p-0 m-0" style="overflow: hidden;">
  
    <!-- <div class="col-md-12 p-0 m-0 header-class" >
        <h3 class="header-title">We'd love your feedback</h3>
        </div> -->

        <div class="col-md-12 p-2 m-0 header-class" style="display: flex;justify-content: space-between;align-items: center;" >
            <h3 class="header-title">We'd love your feedback</h3>
            <img (click)="closeDialog()" height="13px" style="margin-right: 13px !important;" class="close_popup cursor-pointer" src="../../../assets/svg/feedback/close.svg"/>
        </div>

        <div class="row m-0 ml-2 mr-2 mt-3" >
            <div class="col-md-12">
                <span class="lable-style">How would you rate your experience on ProTeen?</span>
            </div>
            <div class="col-md-12 ml-0 pl-0">
                <div class="d-flex titlemargin  titleMobile ml-0 pl-0" >
                    <div class="item">
                        <label for="0">
                        <input class="radio" type="radio" name="feedback" id="0" [value]="1" [(ngModel)]="sliderValue">
                        <span> <img class="symboll" src="../../../../assets/svg/feedback/poor.svg"></span>
                      </label>
                      </div>

                    <div class="item">
                      <label for="1">
                      <input class="radio" type="radio" name="feedback" id="1" [value]="2" [(ngModel)]="sliderValue">
                      <span> <img class="symboll" src="../../../../assets/svg/feedback/bad.svg"></span>
                    </label>
                    </div>

                      <div class="item">
                        <label for="2">
                        <input class="radio" type="radio" name="feedback" id="2" [value]="3" [(ngModel)]="sliderValue">
                        <span> <img class="symboll" src="../../../../assets/svg/feedback/normal.svg"></span>
                      </label>
                      </div>
                  
                      <div class="item">
                        <label for="3">
                        <input class="radio" type="radio" name="feedback" id="3" [value]="4" [(ngModel)]="sliderValue">
                        <span> <img class="symboll" src="../../../../assets/svg/feedback/good.svg"></span>
                      </label>
                      </div>
                  
                      <div class="item">
                        <label for="4">
                        <input class="radio" type="radio" [(ngModel)]="sliderValue" name="feedback" id="4" [value]="5" checked >
                        <span> <img class="symboll" src="../../../../assets/svg/feedback/best.svg" ></span>
                      </label>
                      </div>
                  

                </div>
                <!-- <mat-slider class="siderClass" style="width: 100% !important;"
                 [ngClass]="{bad: sliderValue == 1,poor:sliderValue == 2,normal:sliderValue == 3,good:sliderValue == 4,best:sliderValue == 5}" max="5" min="1"   [(ngModel)]="sliderValue" step="1" aria-label="unit(s)"></mat-slider> -->
            </div>

            <div class="col-md-12 mt-2">
                <span class="lable-style">Do you have any additional comments?</span>
            </div>
            <div class="col-md-12 mt-2" *ngIf="isLargeDevice">
                <textarea [(ngModel)]="feedbacktext" placeholder="Please enter your comment here" rows="4" cols="54" style="height: 70px;width: 94% !important;"></textarea>           
                 </div>
                 <div class="col-md-12 mt-2" *ngIf="isSmallDevice">
                    <textarea  style="height: 70px;width: 95% !important;margin-left: 0px;" [(ngModel)]="feedbacktext" placeholder="Please enter your comment here" rows="4" cols="42"></textarea>           
                     </div>

        </div>
    <div class="row ml-2 mr-2" mat-dialog-actions>
        <div *ngIf="!alertMsgFlag" class="col-sm-12 text-center pt-1 pb-4">
            <!-- <button mat-flat-button class="action-btn dialog-btn cancel-button shadowClass action-btn-text w-100"
                (click)="closeDialog()">Cancel</button> -->
            <button mat-flat-button class="action-btn dialog-btn m-widht shadowClass action-btn-text feedback-submit"
                (click)="onConfirm()" translate="no">Submit</button>
        </div>
     
    </div>
</div>