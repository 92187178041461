import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerPopupComponent } from '../disclaimer/disclaimer-popup.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  

  disclaimerPopup(): void {
    const dialogRef = this.dialog.open(DisclaimerPopupComponent, {
      width: "470px",
      height: "285px",
      panelClass: 'modalboxForFeed'
    });
  }

}
