import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {

  constructor(private ref: ElementRef) {
    //console.log(this.ref.nativeElement);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: any): void {
    if(this.ref.nativeElement.querySelector('.ng-value'))
      this.ref.nativeElement.querySelector('.ng-value').classList.add('d-none');
  }

  @HostListener('close', ['$event'])
  onClose(event: any): void {
    if(this.ref.nativeElement.querySelector('.ng-value')) {
      this.ref.nativeElement.querySelector('.ng-value').classList.remove('d-none');
      
    }
    this.ref.nativeElement.querySelector('input').blur();
  }
}
