<div *ngIf="isLargeDevice">
    <div style="display: flex;justify-content: space-between;" >
        <h2 mat-dialog-title class="form-sub-heading letter-spacing">{{title}}</h2> 
        <img class="close_popup" src="../../../assets/svg/close.svg" (click)="onDismiss()"/>
        <!-- <h2 mat-dialog-title class="form-sub-heading cursor-pointer" (click)="onDismiss()">X</h2>  -->
    </div>
    <div class="mt-2">
        <span mat-dialog-title class="form-sub-heading">{{message}}</span> 
    </div>
    <div class="">
        <div class="col-sm-12" mat-dialog-actions style="display: flex;justify-content: flex-end;">
                <!-- <button  class="action-btn theme-button dialog-btn action-btn-text"
                (click)="onDismiss()">No</button> -->
                <button  class="action-btn theme-button-orange dialog-btn action-btn-text ml-2"
                (click)="onConfirm()">Yes</button>
        </div>
    </div>
</div>
<div *ngIf="isSmallDevice" mat-dialog-content>
    <div class="mt-2">
        <div mat-dialog-title class="form-sub-heading-mob" [innerHTML]="message"></div> 
    </div>
    <div class="">
        <div class="col-sm-12 pb-3" mat-dialog-actions style="display: flex;justify-content: space-around;">
                <button  class="action-btn theme-button-confirm dialog-btn action-btn-text"
                (click)="onDismiss()">No</button>
                <button  class="action-btn  theme-button-cancel dialog-btn action-btn-text"
                (click)="onConfirm()">Yes</button>
        </div>
    </div>
</div>