import { StudentService } from './../student/services/student.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BnNgIdleService } from 'bn-ng-idle';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {
  SESSION_TIMEOUT_SECONDS = 1800;
  constructor(private bnIdle: BnNgIdleService, 
    private _authService: AuthService,
    private _snackBar:MatSnackBar,
    private _studentService:StudentService,
    private _router:Router) { }

  initTimer() {
    // made !=1 to == 1 for the idle timeout on 10032023
    if(localStorage.getItem('keep_logged_in') != '1') {
      this.bnIdle.startWatching(this.SESSION_TIMEOUT_SECONDS).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.bnIdle.stopTimer()
          if(localStorage.getItem('keep_logged_in')) {
            localStorage.removeItem('keep_logged_in')
          }
          this._snackBar.open('Session expired. Please login again.', 'x', {
            duration: 3 * 1000,
            verticalPosition: 'bottom',
            panelClass: ['error-snackbar']
          });
          // let redirectUrl = '/profile/login' commented on 22032023
          let redirectUrl: string;
          if(this._router.url.includes('/admin')) {
            redirectUrl = '/admin/login'
          } else{
            redirectUrl = '/profile/login'
          }
          this._authService.signOut().then(() => {
            this._studentService.s3Image = ''
            this._router.navigate([redirectUrl])
          })
        }
      });
    }
    
  }
}
