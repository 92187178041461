import { Router } from '@angular/router';
import { LocalstorageService } from './localstorage.service';
import { CookieService } from 'ngx-cookie-service';
// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { Hub, ICredentials } from '@aws-amplify/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	httpOptions = {
		headers: new HttpHeaders({ 
		  'Content-Type': 'application/json',
		  'Authorization': '123',
		})
	  };
	private cognitoUser: CognitoUser & { challengeParam: { email: string } };
	public loggedIn = false;
	private _authState: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();
	authState: Observable<CognitoUser | any> = this._authState.asObservable();
	private loginFail: boolean = false;
	public static SIGN_IN = 'signIn';
	public static SIGN_OUT = 'signOut';
	public static FACEBOOK = CognitoHostedUIIdentityProvider.Facebook;
	public static GOOGLE = CognitoHostedUIIdentityProvider.Google;
	public incorrectInputs: boolean = false;
	public codeMismatch: boolean = false;
	public static otpResendTimeLimit = 30;
	public firstLogin = false;
	public firstProfileLogin = false;
	public firstInterActivePopup = false;
	public firstFeedback = false;


	public passCreated = false;
	public userFirstName: string;
	private apiUrl: string;
	public assessmentHighlight: boolean = false;

	// Get access to window object in the Angular way
	// private window: Window;
	
	constructor(@Inject(DOCUMENT) private document: Document,
		private http: HttpClient,
		private cookieService: CookieService,
		private _router: Router,
		private _localStorage: LocalstorageService) {
		const BASE_API_URL = environment.ApiConfig.student;
		this.apiUrl = `${BASE_API_URL}/student/notification/password`;
		Hub.listen('auth', (data) => {
			const { channel, payload } = data;
			if (channel === 'auth') {
				this._authState.next(payload.event);
			}
		});
		if (localStorage.getItem('token')) {
			this.httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem('token'),
				})

			};
		} else {
			this.httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'xyz',
				})

			};
		}
	}

	VerifyOtp(userAttributes) {
		this.codeMismatch = false;
		return this.http.post(`${this.apiUrl}/validate`, userAttributes, this.httpOptions);
	}

	forgotPasswordCall(userAttributes) {
		this.codeMismatch = false;
		return this.http.post(`${this.apiUrl}/forgot`, userAttributes, this.httpOptions);
	}

	ResetPassword(userAttributes) {
		this.codeMismatch = false;
		return this.http.post(`${this.apiUrl}/change`, userAttributes, this.httpOptions);
	}

	updatePassword(user: any): Promise<CognitoUser | any> {
		// console.log('update pass user request ', user);
		// console.log('cognitouser ', this.cognitoUser);
		return new Promise((resolve, reject) => {
			Auth.completeNewPassword(user.user, user.newPassword)
				.then((user: CognitoUser | any) => {
					// console.log('update pass user ', user);
					resolve(user)
				}).catch((error: any) => reject(error));
		});
		// return Auth.changePassword(user.user, user.oldPassword, user.newPassword)
	}

	signUp(user: any): Promise<CognitoUser | any> {
		console.log('user ', user);
		// let userName = user.fullName.split(' ');
		return Auth.signUp(user);
	}

	signIn(username: string, password: string): Promise<CognitoUser | any> {
		return new Promise((resolve, reject) => {
			Auth.signIn(username, password)
				.then((user: CognitoUser | any) => {
					this.cognitoUser = user;
					console.log("this.cognitoUser", this.cognitoUser);
					this.loggedIn = true;
					resolve(user);
				}).catch((error: any) => {
					reject(error);
				});
		});
	}

	signOut(): Promise<any> {
		return Auth.signOut()
			.then(() => {
				this.clearTestData()
				let uuid = sessionStorage.getItem('userSub')
				sessionStorage.removeItem('userSub');
				this.cookieService.delete('token')
				if (localStorage.getItem('keep_logged_in')) {
					localStorage.removeItem('keep_logged_in')
				}
				if (localStorage.getItem('pinUnpinFlag')) {
					localStorage.removeItem('pinUnpinFlag')
				}
				if (localStorage.getItem('aptiSelectedSecIndex')) {
					localStorage.removeItem('aptiSelectedSecIndex')
				}
				if (localStorage.getItem('sidebarData')) {
					localStorage.removeItem('sidebarData')
				}
				if (localStorage.getItem('sidebarData_parent')) {
					localStorage.removeItem('sidebarData_parent')
				}
				if (localStorage.getItem('sidebarData_admin')) {
					localStorage.removeItem('sidebarData_admin')
				}
				if (localStorage.getItem('admin-student')) {
					localStorage.removeItem('admin-student')
				}
				if (localStorage.getItem('admin-parent')) {
					localStorage.removeItem('admin-parent')
				}
				if (localStorage.getItem('admin-feedback')) {
					localStorage.removeItem('admin-feedback')
				}
				if (localStorage.getItem('admin-student-filters')) {
					localStorage.removeItem('admin-student-filters')
				}
				if (localStorage.getItem('admin-parent-filters')) {
					localStorage.removeItem('admin-parent-filters')
				}
				if (localStorage.getItem('admin-feedback-filters')) {
					localStorage.removeItem('admin-feedback-filters')
				}
				if (localStorage.getItem('admin-student-search')) {
					localStorage.removeItem('admin-student-search')
				}
				if (localStorage.getItem('admin-parent-search')) {
					localStorage.removeItem('admin-parent-search')
				}
				if (localStorage.getItem('admin-feedback-search')) {
					localStorage.removeItem('admin-feedback-search')
				}
				if (localStorage.getItem('token')) {
					localStorage.removeItem('token')
				}
				if (localStorage.getItem(`${uuid}_basic`)) {
					localStorage.removeItem(`${uuid}_basic`)
				}

				this.loggedIn = false;
			});
	}

	clearTestData() {
		let assessmentKeys = ['MI', 'interest', 'personality', 'aptitude', 'numerical', 'perception', 'mechanical', 'accuracy', 'verbal', 'spatial', 'reasoning'];
		assessmentKeys.forEach((singleKey) => {
			if (localStorage.getItem(singleKey)) {
				localStorage.removeItem(singleKey)
			}
			if (localStorage.getItem(`${singleKey}_time`)) {
				localStorage.removeItem(`${singleKey}_time`)
			}
			if (localStorage.getItem(`${singleKey}_questions`)) {
				localStorage.removeItem(`${singleKey}_questions`)
			}
		})
	}

	checkBasicData() {
		this.getCurrentUserObj()
			.then((cognitoUser) => {
				if (cognitoUser.attributes['custom:isDefaultPassword'] == 'true') {
					this._router.navigate(['/proteen/create-password']);
				}
				else {
					let userSub = sessionStorage.getItem('userSub')
					let basicData = this._localStorage.get(`${userSub}_basic`);

					if (!basicData) {
						return;
					}
					if (!basicData.first_name || !basicData.email || !basicData.phone_number || !basicData.gender) {
						this._router.navigate(['/student/dashboard']);
					}
				}
			}).catch(err => console.log(err))


	}

	/* socialSignIn(provider:CognitoHostedUIIdentityProvider): Promise<ICredentials> {
	  return Auth.federatedSignIn({
		'provider': provider
	  });
	} */

	getCurrentAuthUser() {
		return Auth.currentAuthenticatedUser()
	}

	getUserDetails(): Promise<CognitoUser | any> {
		return new Promise((resolve, reject) => {
			if (Object.keys(environment.currentLoginUser.user).length === 0 && environment.currentLoginUser.user.constructor === Object) {
				Auth.currentAuthenticatedUser()
					.then((user) => {
						// this.cognitoUser = user;
						environment.currentLoginUser.user = user;
						let session = user.signInUserSession;
						environment.currentLoginUser.sessionIdInfo = jwtDecode(session.idToken.jwtToken);
						Auth.userAttributes(user)
							.then((userDetails) => {
								environment.currentLoginUser.userAttribute = userDetails;
								resolve(userDetails)
							}).catch((error: any) => reject(error));
					}).catch((error: any) => reject(error));
			} else {
				// console.log('environment ', environment.currentLoginUser);
				resolve(environment.currentLoginUser);
			}
		})
	}

	getAttributes(): Promise<CognitoUser | any> {
		return new Promise((resolve, reject) => {
			Auth.currentAuthenticatedUser()
				.then((user) => {
					Auth.userAttributes(user)
						.then((data) => {
							console.log(data);
							resolve(data)
						})
						.catch((error: any) => {
							console.log('error ', error);
							reject(error);
						})
				}).catch((error: any) => reject(error));
		})
	}

	resendConfirmationCode(user: any): Promise<CognitoUser | any> {
		return new Promise((resolve, reject) => {
			Auth.resendSignUp(user)
				.then(() => {
					console.log('resend');
					resolve('code resend');
				})
				.catch((error: any) => {
					console.log('error ', error);
					reject(error);
				})
		});


		// console.log('code resent successfully');

	}

	emailVerification(): Promise<any> {
		// let userAttr = this.getUserDetails();

		/* return this.getUserDetails()
		.then((userAttr) => {
			console.log(userAttr); */
		return Auth.verifyCurrentUserAttribute('email')
			.then((data) => { console.log('verification code send', data); })
			.catch((error) => console.log(error));
		// })
		/* console.log('userAttr[0].Value ', userAttr[0].Value)
		return Auth.verifyCurrentUserAttribute(userAttr[0].Value)
		.then(() => console.log('verification code send')) */
	}

	updateEmailAddress(user, email): Promise<any> {
		console.log(environment)
		return Auth.updateUserAttributes(user, { 'email': email })
			.then((data) => { console.log(data) })
			.catch((error) => { console.log(error) })
	}

	updateUserRole(user, user_role): Promise<any> {
		console.log(environment)
		return Auth.updateUserAttributes(user, { 'custom:role': user_role })
			.then((data) => { console.log(data) })
			.catch((error) => { console.log(error) })
	}

	updatePasswordStatus(user, status): Promise<any> {
		console.log(environment)
		return Auth.updateUserAttributes(user, { 'custom:isDefaultPassword': status })
			.then((data) => { console.log(data) })
			.catch((error) => { console.log(error) })
	}

	getCurrentUserObj(): Promise<any> {
		return Auth.currentUserPoolUser();
	}

	getCurrentSession(): Promise<any> {
		return Auth.currentSession();
	}


	emailVerificationCodeSubmit(attr: string = 'email', code: string): Promise<any> {
		return Auth.verifyCurrentUserAttributeSubmit(attr, code)
			.then((data) => { console.log('Email is verified', data); return data; })
			.catch((error) => { console.log(error); return error; });
	}

	sendEmailVerificationCode(attr: string = 'email', user) {
		return Auth.verifyUserAttribute(user, attr)
			.then((data) => { console.log(data) })
			.catch((error) => { console.log(error) })
	}

	forgotPassword(username): Promise<any> {
		this.codeMismatch = false;
		// console.log('username ', username)
		return Auth.forgotPassword(username)
			.then((data) => { console.log(data); return data; })
			.catch(err => { console.log(err); return err; });
	}


	forgotPasswordSubmit(user: any): Promise<any> {
		// Collect confirmation code and new password, then
		return Auth.forgotPasswordSubmit(user.username, user.code, user.new_password)
			.then((data) => { console.log(data); return true; })
			.catch(err => {
				console.log(err);
				this.codeMismatch = true;
			});
	}

	socialSignIn(provider: CognitoHostedUIIdentityProvider): Promise<ICredentials> {
		return Auth.federatedSignIn({
			'provider': provider
		});
	}

	// create a new method to generate new session and than save into local storage on 28022023
	async handleRefreshToken(callback) {
		console.log("line no. 393 Your session has expired");
		const congnitoUser = await this.getCurrentAuthUser();
		const currentSession = await this.getCurrentSession();
		congnitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
			if (err) {
				console.log("line no. 395 handleRefreshToken", err);
			} else {
				const { idToken, refreshToken, accessToken } = session;
				// save the token into web storage
				localStorage.setItem('token', idToken.jwtToken);
				this._localStorage.setCookie('token', idToken.jwtToken);
				callback();
			}
		})
	}

}
