import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'career-fit-graph',
  templateUrl: './career-fit-graph.component.html',
  styleUrls: ['./career-fit-graph.component.scss']
})
export class CareerFitGraphComponent implements OnInit {
  @Input() careerFitData;
  @Input() careerClusterTitle;
  @Input() headingName;
  @Input() streamFit;
  @Input() showAsterisk;
  constructor() { }

  ngOnInit(): void {
  }

  returnClrCode(clrCode) {
    if (clrCode == 'H') {
      return '#8CC63F'
    }
    else if (clrCode == 'M') {
      return '#22A7B6'
    }
    else {
      return '#E7551C'
    }
  }

  returnProgressClrCode(clrCode) {
    if (clrCode == 'H') {
      return 'custom-bar-high'
    }
    else if (clrCode == 'M') {
      return 'custom-bar-moderate'
    }
    else {
      return 'custom-bar-low'
    }
  }

  returnImgInterest(test, clrCode) {
    let img = "assets/svg/Careers/career-fit-icons/" + test + "-" + clrCode + ".svg"
    return img;
  }

}
