import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudentService } from 'src/app/student/services/student.service';
import { FacebookService } from '../../services/facebook.service';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss']
})
export class FacebookComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private _facebookService:FacebookService,
    private _studentService:StudentService,
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe((data:any) => {
      
      if(data.code) {
        console.log(data);
        this._facebookService.requestAccessToken(data.code).subscribe((dataResponse:any) => {
          console.log(dataResponse);
          dataResponse = JSON.parse(dataResponse)
          let mediaArray = JSON.parse(localStorage.getItem('social'));
          let saved = false;
          mediaArray.forEach((element) => {
            if(element.social_media == 'Facebook') {
              element.access_token = dataResponse.access_token;
              element.expiry = dataResponse.expires_in;
              saved = true;
            }
          })
          //if facbeook doesnt exist
          if(!saved) {
            let obj = {
              social_media:'Facebook',
              access_token:dataResponse.access_token,
              expiry:dataResponse.expires_in
            }
            mediaArray.push(obj)
          }
          
          this._studentService.updateStudentDetails({social_media:mediaArray},'basic').subscribe((response:any) => {
            if(response.success) {
              localStorage.setItem('facebook_linked','1');
              window.close();
              //this._facebookService.showSnackSuccess();
            }
          }) 
        },
        error => {
         // window.close();
          console.log(error);
        }
        )
      }
    },error => {
      // /window.close();
      console.log(error)
    },)
  }

}
