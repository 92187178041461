import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryselectService } from 'src/app/shared/services/countryselect.service';
import { OtherService } from 'src/app/student/services/other.service';
import { SettingsService } from 'src/app/student/services/settings.service';
import { StudentService } from 'src/app/student/services/student.service';
import { threadId } from 'worker_threads';
import { AdminService } from '../../../services/admin.service';


@Component({
  selector: 'bulkedit-dialog',
  templateUrl: './bulkedit-dialog.component.html',
  styleUrls: ['./bulkedit-dialog.component.scss']
})
export class BulkeditDialogComponent implements OnInit {
  title: string;
  message: string;
  lableName: any;
  collegeName: any;
  plan:any;
  shortName: any;
  countryList: any;
  stateList: any;
  state: any;
  country:any;
  dropdownList: any;
  city: any;
  cityList: any;
  constructor(private spinner: NgxSpinnerService,
    private _countrySelect: CountryselectService,
    private _adminService: AdminService,
    private _otherService: OtherService,
    private _settingsService: SettingsService,
    private _studentService: StudentService,   
    private router: Router,


    public dialogRef: MatDialogRef<BulkeditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkeditDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.lableName = data.lableName;

  
  }

  ngOnInit() {
    this.getPlanList()
    this.getCountries();

  }

  addCollege() {
    let collegelist = {
      title: this.collegeName,
    }
    this._settingsService.addcollegesDetails(collegelist).subscribe(
      data => {
        this._adminService.showMessageForAdmin('Changes Saved!', 'success-snackbar');
        this._studentService.bulkEditIntimate.next('refresh');  

        this.dialogRef.close(true);
      

      },
      error => {
        this.spinner.hide();
      }
    );
  }


  getPlanList() {
    this._studentService.getSubscriptionPlansList().subscribe(
      data => {
        this.dropdownList =data
      },
      error => { }
    );
  }
  
  onConfirm(): void {
    this.spinner.show();
    let data = {
      "state":this.state,
      "country":this.country,
      "city":this.city,
      "plan_details":{"plan":this.plan},
      "uuids": this.lableName
    }

      this._studentService.maskUpdateStudentDetails(data).subscribe(
        data => {
          if(data['success']){
            this.reloadCurrentRoute();
            this._adminService.showMessageForAdmin('Changes Saved!','success-snackbar');
            this.onDismiss();
            this.spinner.hide();

          }else{
            this.spinner.hide();
            this._adminService.showMessageForAdmin('update unsuccessful!','error-snackbar');
            this.onDismiss();
          }
  
        },
        error => {
          this.spinner.hide();
          console.log("error",error);
        }
      )
    
  }
  reloadCurrentRoute() {

      let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
    
}

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
    this.reloadCurrentRoute();

  }
  
  getCountries() {
    if(localStorage.countries) {
      this.countryList = JSON.parse(localStorage.countries)
    }
    else {
      this._countrySelect.getCountries().subscribe((data:any) => {
        this.countryList = data;
        localStorage.countries = JSON.stringify(data);

      })
    }
  }

  getStates(country) {
      this._countrySelect.getStates(country).subscribe((data:any) => {
        if(data.error) {
          this.stateList = []
        }
        else {
          this.stateList = data;
        }
      

      })
    
  }

  onCountrySelect(event) {
    this.updateStates(event);
    //this.state.setValue(null);
    this.city = null;
  }

  updateStates(event) {
    this.getStates(event)
    this.resetCities();
  }
  
  resetCities() {
    this.cityList = [];
  }

  onStateSelect(event) {
    this.getCities(event);
    this.city = null;
  }

  getCities(state) {
    this._countrySelect.getCities(state).subscribe((data:any) => {
      if(data.error) {
        this.cityList = []
      }
      else {
        this.cityList = data;
      }

    }) 
}

}





/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class BulkeditDialogModel {

  constructor(public title: string, public message: string, public lableName: any) {
  }
}
