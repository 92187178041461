import { AutoSocialLoginComponent } from './components/auto-social-login/auto-social-login.component';
import { AutologinComponent } from './components/autologin/autologin.component';
import { AicteComponent } from './components/aicte/aicte.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './authentication/components/main-layout/main-layout.component';
import { NavbarComponent } from './student/components/navbar/navbar.component';
const routes: Routes = [
  {
    path: "",
    redirectTo: "proteen/login",
    pathMatch: "full"
  },
  {
    path: "proteen",
    component: MainLayoutComponent,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  
  {
    path: "aicte-checkout",
    component:AicteComponent
  },
  {
    path: "autologin",
    component:AutologinComponent
  },
  {
    path: "auto-social-login",
    component:AutoSocialLoginComponent
  },
  {
    path: '',
    component: NavbarComponent,
    children:[
        {
          path: "student",
          loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
        },
        {
          path: "assessment",
          loadChildren: () => import('./assessments/assessments.module').then(m => m.AssessmentsModule)
        },
        {
          path: "career",
          loadChildren: () => import('./career-recom/career-recom.module').then(m => m.CareerRecomModule)
        },
        {
          path: "pricing",
          loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule)
        }
  ]},
  {
    path: "admin",
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "parent",
    loadChildren: () => import('./parent/parent.module').then(m => m.ParentModule)
  },
  {
    path: "shared",
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: "**",
    redirectTo: "proteen/login",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
