import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Content-Type': 'application/json',
    'Authorization': '',
  })
};
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private apiUrl:string;

  constructor(private http:HttpClient) { 
    const BASE_API_URL = environment.ApiConfig.student;
    this.apiUrl = BASE_API_URL;
  }

  sendEmail(recipients, subject, body) {
    let emailData = {
      recipients:[...recipients],
      subject:subject,
      html:body
    }
    return this.http.post(`${this.apiUrl}/student/notification/email`,emailData);
  }

}
