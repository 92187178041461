import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  public isSmallDevice: boolean = false;
  public isLargeDevice: boolean = false;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,public breakpointObserver: BreakpointObserver, ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
     //Responsive UI
     this.breakpointObserver
     .observe([Breakpoints.Small, Breakpoints.XSmall])
     .subscribe((state: BreakpointState) => {
       if (state.matches) {
         this.isSmallDevice = true;
         this.isLargeDevice = false;
       }
     });
     this.breakpointObserver
     .observe([Breakpoints.Tablet])
     .subscribe((state: BreakpointState) => {
       if (state.matches) {
         this.isSmallDevice = true;
         this.isLargeDevice = false;
       }
     });
     this.breakpointObserver
     .observe([Breakpoints.Large, Breakpoints.XLarge])
     .subscribe((state: BreakpointState) => {
       if (state.matches) {
         this.isLargeDevice = true;
         this.isSmallDevice = false;
       }
     });
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string ) {
  }
}
