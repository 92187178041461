import { WhyUpgradePlanComponent } from './../shared/components/why-upgrade-plan-popup/why-upgrade-plan.component';
import { FeatureService } from './../services/feature.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { StudentService } from '../student/services/student.service';


/**
 * Prevent access to routes if access-token is not present.
 * 
 * @export
 * @class FeatureGuard
 * @implements {CanActivate}
 */
@Injectable()
export class FeatureGuard implements CanActivate {
  featureList = [];
  constructor(private _router: Router, 
    private _authService: AuthService, 
    private _featureService:FeatureService,
    public dialog: MatDialog,
    private _snackBar:MatSnackBar,
    private studentService:StudentService) {}

  //List of all routes
  //Default routes are accessible even if there no subscription plan
  featureRouteMap = {
    /*"Default" : [
      "/student/profile",
      "/student/dashboard",
      "/linkedin",
      "/facebook",
      "/twitter",
      "/parent/dashboard",
      "/parent/profile"
    ],*/
    "Interest" : [
      "/assessment/interest/instructions",
      "/assessment/interest/question",

    ],
    "Multiple Intelligences" : [
      "/assessment/mi/instructions",
      "/assessment/mi/question",
    ],
    "Personality" : [
      '/assessment/personality/instructions',
      '/assessment/personality/question'
    ],
    "Aptitude" : [
      '/assessment/aptitude/instructions',
      '/assessment/aptitude/question/accuracy',
      '/assessment/aptitude/question/perception',
      '/assessment/aptitude/question/numerical',
      '/assessment/aptitude/question/mechanical',
      '/assessment/aptitude/question/verbal',
      '/assessment/aptitude/question/reasoning',
      '/assessment/aptitude/question/spatial',

    ],
    "Assessment" : [
      "/assessment/psychometricAssessment",
      "/assessment/basic-test"
      //"/student/assessment-instructions",
    ],
    "Resume Builder" : [
     // "/student/assessment-instructions",
     "/assessment/essential-test"
    ],
    "College Finder" : [
      "/assessment/premium-test"
    ]
  }

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this._authService.getUserDetails().then(() => {
        //FEATURE FETCH API
        if(this.featureList.length > 1) {
          if(this.isRouteAllowed(this.featureList,state.url)) {
            return true;
          }
          else {
            this._router.navigate(['/student/dashboard']);
            this.studentService.highlightLeftMenu('/student/dashboard','parent')
            this.throwSnackError()            
            return false;
          }
        }
        else {
          this._featureService.getFeatureDetails().subscribe((featureList:any) => {
            //featureList[featureList.length] = {features:"Default"}
            this.featureList = featureList;
            if(this.isRouteAllowed(featureList,state.url)) {
              return true;
            }
            else {
              this._router.navigate(['/student/dashboard']);
              this.studentService.highlightLeftMenu('/student/dashboard','parent')
              this.throwSnackError()            
              return false;
            }
          },
          error => {
              console.log(error);
              return false;
          });
        }
        
        return true; 
      })
    .catch(() => {
        this._router.navigate(['/proteen/login']);
        return false;
      });

    return true;

  }

  throwSnackError() {
    const message = `education-board`;
    const lableName = 'Board Name'
   
    const dialogData = {};
    const dialogRef = this.dialog.open(WhyUpgradePlanComponent, {
      panelClass:'dialog-responsive',
      width: "70%",
      height: "90%",
      autoFocus: false,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        

      }
    });
    /*this._snackBar.open('Feature is not a part of the current plan.', 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: ['error-snackbar']
    });*/
  }

  isRouteAllowed(featureList,url) {
    let allow = false;
    featureList.forEach(element => {
      if(this.featureRouteMap[element.features]) {
        if(allow) {
          return;
        }
        else {
          allow = this.featureRouteMap[element.features].includes(url)
          //return allow;
        }
        
        
      }
      
    });
    return allow;
  }

  clearFeatures() {
    this.featureList = []
  }
}

