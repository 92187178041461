<div class="container">
    <div class="row">
        <div class="col-sm-12 d-flex">
                <div class="material-icons alert-icon">
                        report_problem
                </div>
                <div class="alert-text">
                    ProTeen App does not support landscape mode, please switch back to portrait!
                </div>
        </div>
    </div>
</div>