
<div class="container p-0 m-0" style="overflow: hidden;">
  
    <!-- <div class="col-md-12 p-0 m-0 header-class" >
        <h3 class="header-title">We'd love your feedback</h3>
        </div> -->

        <div class="col-md-12 p-2 m-0 header-class" style="display: flex;justify-content: space-between;align-items: center;" >
            <h3 class="header-title"><img style="width: 16px;margin-right: 7px;" src="../../../../assets/images/fullReport/disclamer-W.svg" alt="">
                Disclaimer</h3>
            <img (click)="closeDialog()" height="13px" style="margin-right: 13px !important;" class="close_popup cursor-pointer" src="../../../assets/svg/feedback/close.svg"/>
        </div>

        <div class="row m-0 ml-2 mr-2 mt-3" >
            <div class="col-md-12">
                <span class="lable-style">
                    The results of assessment can often differ from an individual’s perception of
themselves (or a parent’s perception of their child). This can be due to difference
in the way a person acts in different companies for e.g. Friends and Family.
                </span>
            </div>
            <div class="col-md-12 mt-3">
                <span class="lable-style">
                    It can also be attributed to the way in which the assessment questions were
interpreted by the test taker or even the testing conditions in which the
assessment was taken. The discrepancy does not render the assessment results
incorrect neither does it mean that the assessment is wrongly constructed.
                </span>
            </div>
       

        </div>
    
</div>