import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StudentService } from '../student/services/student.service';


/**
 * Prevent access to routes if access-token is not present.
 * 
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _authService: AuthService,private studentService:StudentService ) { }

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // return Auth.currentAuthenticatedUser().then(() => { return true; })
      return this._authService.getUserDetails().then((userData) => {
        this._authService.getCurrentUserObj().then(cognitoUser => {
          let hasRole = cognitoUser.attributes['custom:role']
          if(hasRole) {
            if(hasRole == 'Parent') {
              //Allow /parent only
              if(state.url.indexOf('parent') > -1) {
                return true;
              }
              else {
                this._router.navigate(['/parent/dashboard']);
                return false;
              }
            }
            if(hasRole == 'Student') {
              //Disallow parent, allow the rest
              if(state.url.indexOf('parent') > -1) {
                this._router.navigate(['/student/dashboard']);
                this.studentService.highlightLeftMenu('/student/dashboard','parent')
                return false;
              }
              else {
                return true;
              }
            }
            
          }
          else {
            this._router.navigate(['/proteen/select-role']);
            return false;
          }
        }).catch(error => {
          this._router.navigate(['/proteen/login'],{ queryParams: { returnUrl: state.url }});
          return false;
        })
        return true;
      })
    .catch((error) => {
        this._router.navigate(['/proteen/login'],{ queryParams: { returnUrl: state.url }});
        return false;
      });

    return true;

  }
}