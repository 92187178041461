import { environment } from './../../../../../environments/environment';
import { ThrowStmt } from '@angular/compiler';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EmailService } from './../../../../services/email.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit, OnDestroy {
  mailForm : FormGroup;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string = '';
  mailSubject:string = '';
  log: string = '';
  signatureHost = "https://develop.d3bf9b4lgay1rh.amplifyapp.com";
;
  htmlContent = `
  <p>Email Body</p>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <hr/>
  <p style="font-family: 'sf_ui_displaylight'; margin-bottom: 9px;">Thanks & Regards,</p>
  <p><img src="${this.signatureHost}/assets/svg/proteenMailSign.svg" style="width:150px; height: auto; display: block;"></p>`;
  emailSubscription:Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  private formBuilder: FormBuilder,
  private router: Router,
  public dialogRef: MatDialogRef<SendMailComponent>,
  private _snackBar: MatSnackBar,
  private _emailService:EmailService) { 
    if(environment.env == 'test') {
      this.signatureHost = "https://test.d3bf9b4lgay1rh.amplifyapp.com";
    }
  }
  ngOnInit(): void {
   // console.log(this.data)
   this.data = this.data.map(element => element.email).filter(element => this.validateEmail(element));
   //console.log(this.data)
    /*this.mailForm = new FormGroup({
      mailSubject : new FormControl(""),
      mailBody: new FormControl(""),
      mailAttachment: new FormControl(""),
    });
    this.ckeConfig = {
      removePlugins: 'undo'
    };*/
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Email Body',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }

  showHTML(){
    this.htmlContent = document.getElementById("htmlDiv").innerHTML;
  
  }
  Close(){
    this.dialogRef.close(false);
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {

    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
  
}

  sendEmail(){
    //console.log('email body',this.mycontent)
    this.emailSubscription = this._emailService.sendEmail(this.data,this.mailSubject,this.htmlContent).subscribe((response:any) => {
      //console.log(response);
      if(response.success) {
        this.dialogRef.close(true)

        this._snackBar.open('Email has been sent!', 'x', {
          duration: 3 * 1000,
          verticalPosition: 'bottom',
          panelClass: ['success-snackbar']
        });
        this.reloadCurrentRoute();
      }
      else {
        this._snackBar.open('An error occured!', 'x', {
          duration: 3 * 1000,
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
      
    },
    error => {
      console.log(error)
      this.reloadCurrentRoute();
    }
    )
  }

  
  validateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true
    }
    return false
  }

  ngOnDestroy() {
   if(this.emailSubscription) 
    this.emailSubscription.unsubscribe();
    throw new Error('Method not implemented.');
  }

}
